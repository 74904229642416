// src/components/professor/ManageSession.js

import React, { useState, useEffect, useRef } from 'react';
import { getDatabase, ref, set, update, push, onValue, serverTimestamp } from 'firebase/database';
import { Button, Form, Modal, Row, Col, Card } from 'react-bootstrap';
import Chart from 'chart.js/auto';

const ManageSession = ({ classId, courseId }) => {
  const [sessionId, setSessionId] = useState(null);
  const [questionNumber, setQuestionNumber] = useState(1);
  const [questionType, setQuestionType] = useState('multiple_choice');
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [answersData, setAnswersData] = useState({});
  const [isPollActive, setIsPollActive] = useState(false);
  const [pollTimer, setPollTimer] = useState(0);

  const chartRef = useRef(null);
  const canvasRef = useRef(null);
  const pollIntervalRef = useRef(null);

  // Fetch session and poll state on component mount
  useEffect(() => {
    const database = getDatabase();

    // Check for existing active session
    const sessionsRef = ref(database, `sessions/${courseId}`);
    const unsubscribeSession = onValue(sessionsRef, (snapshot) => {
      const sessions = snapshot.val();
      let activeSession = null;
      if (sessions) {
        for (let key in sessions) {
          if (!sessions[key].end_date_time) {
            activeSession = { id: key, ...sessions[key] };
            break;
          }
        }
      }
      if (activeSession) {
        setSessionId(activeSession.id);
        setIsSessionActive(true);
        // Fetch poll state
        fetchPollState(activeSession.id);
      }
    });

    // Cleanup listener on unmount
    return () => {
      unsubscribeSession();
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }
    };
  }, [courseId]);

  const fetchPollState = (sessionId) => {
    const database = getDatabase();
    const pollStateRef = ref(database, `sessions/${courseId}/${sessionId}/pollState`);
    onValue(pollStateRef, (snapshot) => {
      const pollState = snapshot.val();
      if (pollState) {
        setIsPollActive(pollState.isPollActive);
        setQuestionNumber(pollState.questionNumber);
        setQuestionType(pollState.questionType);
        if (pollState.isPollActive) {
          startPollTimer(pollState.pollStartTime);
        } else {
          setPollTimer(0);
          if (pollIntervalRef.current) {
            clearInterval(pollIntervalRef.current);
          }
        }
      }
    });
  };

  const startPollTimer = (pollStartTime) => {
    if (pollIntervalRef.current) {
      clearInterval(pollIntervalRef.current);
    }
    pollIntervalRef.current = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - pollStartTime) / 1000);
      setPollTimer(elapsedTime);
    }, 1000);
  };

  const startSession = async () => {
    try {
      const database = getDatabase();
      const sessionRef = push(ref(database, `sessions/${courseId}`));
      const newSessionId = sessionRef.key;
      setSessionId(newSessionId);

      await set(sessionRef, {
        start_date_time: serverTimestamp(),
        total_questions: 0,
        questions: {},
      });

      // Initialize poll state in the database
      await set(ref(database, `sessions/${courseId}/${newSessionId}/pollState`), {
        isPollActive: false,
        questionNumber: 1,
        questionType: 'multiple_choice',
        pollStartTime: null,
      });

      setIsSessionActive(true);
      console.log(`New session created with ID: ${newSessionId}`);
    } catch (error) {
      console.error('Failed to create session:', error.message);
    }
  };

  const endSession = async () => {
    try {
      const database = getDatabase();
      const sessionRef = ref(database, `sessions/${courseId}/${sessionId}`);
      await update(sessionRef, {
        end_date_time: serverTimestamp(),
      });
      setIsSessionActive(false);
      setSessionId(null);
      console.log(`Session ${sessionId} for course ${courseId} ended.`);
    } catch (error) {
      console.error('Failed to update session end time:', error.message);
    }
  };

  const addQuestion = async () => {
    try {
      const database = getDatabase();
      const questionRef = ref(database, `sessions/${courseId}/${sessionId}/questions/${questionNumber}`);
      await set(questionRef, {
        question_type: questionType,
        question_photo: 'InClass',
        question_number: questionNumber,
        pool: 'on',
      });
      console.log(`Question ${questionNumber} added to session ${sessionId}`);

      // Update poll state in the database
      const pollStateRef = ref(database, `sessions/${courseId}/${sessionId}/pollState`);
      await update(pollStateRef, {
        isPollActive: true,
        questionNumber: questionNumber,
        questionType: questionType,
        pollStartTime: Date.now(),
      });

      // Reset local state
      setIsPollActive(true);
      setPollTimer(0);
      setAnswersData({});
      startPollTimer(Date.now());

      // Reset chart data
      if (chartRef.current) {
        chartRef.current.data.labels = [];
        chartRef.current.data.datasets[0].data = [];
        chartRef.current.update();
      }
    } catch (error) {
      console.error('Error adding question to session:', error.message);
    }
  };

  const closePoll = async () => {
    try {
      const database = getDatabase();
      const poolRef = ref(database, `sessions/${courseId}/${sessionId}/questions/${questionNumber}`);
      await update(poolRef, {
        pool: 'off',
      });
      console.log(`Poll closed for question ${questionNumber} in session ${sessionId}`);

      // Update poll state in the database
      const pollStateRef = ref(database, `sessions/${courseId}/${sessionId}/pollState`);
      await update(pollStateRef, {
        isPollActive: false,
        pollStartTime: null,
      });

      // Reset local state
      setIsPollActive(false);
      setPollTimer(0);
      if (pollIntervalRef.current) {
        clearInterval(pollIntervalRef.current);
      }

      // Prompt for correct answer
      setShowPromptModal(true);
      initializeChart();
    } catch (error) {
      console.error('Error updating poll status:', error.message);
    }
  };

  const startPoll = async (e) => {
    e.preventDefault(); // Prevent default form submission
    await addQuestion();
  };

  const stopPoll = async (e) => {
    e.preventDefault(); // Prevent default form submission
    await closePoll();
  };

  const submitCorrectAnswer = async (e) => {
    e.preventDefault(); // Prevent default form submission
    try {
      const database = getDatabase();
      const answerRef = ref(database, `sessions/${courseId}/${sessionId}/questions/${questionNumber}`);
      await update(answerRef, {
        correct_answer: correctAnswer,
      });
      console.log(`Correct answer for question ${questionNumber} updated in session ${sessionId}`);
      setShowPromptModal(false);
      setCorrectAnswer('');

      // Increment question number for the next question
      setQuestionNumber((prev) => prev + 1);

      // Update poll state in the database
      const pollStateRef = ref(database, `sessions/${courseId}/${sessionId}/pollState`);
      await update(pollStateRef, {
        questionNumber: questionNumber + 1,
      });
    } catch (error) {
      console.error('Error updating correct answer:', error.message);
    }
  };

  // Initialize Chart when session starts and canvas is available
  useEffect(() => {
    if (isSessionActive && canvasRef.current) {
      initializeChart();
    }

    // Cleanup function to destroy chart on unmount or when isSessionActive changes
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [isSessionActive]);

  const initializeChart = () => {
    // Destroy existing chart instance if it exists
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = canvasRef.current.getContext('2d');
    const newChartInstance = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            label: '# of Answers',
            data: [],
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        animation: false,
        scales: {
          y: { beginAtZero: true },
        },
      },
    });
    chartRef.current = newChartInstance;
  };

  // Update Chart when answersData changes
  useEffect(() => {
    if (chartRef.current && answersData) {
      chartRef.current.data.labels = Object.keys(answersData);
      chartRef.current.data.datasets[0].data = Object.values(answersData);
      chartRef.current.update();
    }
  }, [answersData]);

  // Listen for answers data
  useEffect(() => {
    let unsubscribe = null;
    if (isSessionActive && isPollActive) {
      const database = getDatabase();
      const answersRef = ref(database, `answers/${courseId}/${sessionId}/${questionNumber}`);
      unsubscribe = onValue(answersRef, (snapshot) => {
        const answers = snapshot.val();
        if (answers) {
          const counts = {};
          Object.values(answers).forEach((answer) => {
            const ans = answer.answer;
            counts[ans] = (counts[ans] || 0) + 1;
          });
          setAnswersData(counts);
        } else {
          setAnswersData({});
        }
      });
    }

    // Cleanup subscription on unmount or when dependencies change
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [isSessionActive, isPollActive, questionNumber, courseId, sessionId]);

  return (
    <div className="container">
      <h3 className="my-3">Manage Session</h3>
      {!isSessionActive ? (
        <button type="button" className="btn custom-btn" onClick={startSession}>
          Start Session
        </button>
      ) : (
        <>
          <button
            type="button"
            className="btn custom-btn"
            onClick={endSession}
            disabled={isPollActive} // Disable when poll is active
          >
            End Session
          </button>
          <Row className="mt-4">
            <Col md={4}>
              <Card>
                <Card.Body>
                  <Form>
                    <Form.Group controlId="questionType">
                      <Form.Label>Question Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={questionType}
                        onChange={(e) => setQuestionType(e.target.value)}
                        disabled={isPollActive || showPromptModal}
                      >
                        <option value="multiple_choice">Multiple Choice</option>
                        <option value="yes_no">Yes/No</option>
                        <option value="short_input">Short Input</option>
                      </Form.Control>
                    </Form.Group>
                    <button
                      type="button"
                      className="btn custom-btn mt-3"
                      onClick={isPollActive ? stopPoll : startPoll}
                      disabled={showPromptModal}
                    >
                      {isPollActive ? 'Stop Poll' : 'Start Poll'}
                    </button>
                    {isPollActive && <h5 className="mt-3">Time Elapsed: {pollTimer}s</h5>}
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <Card.Body>
                  <h5>Question #{questionNumber}</h5>
                  <canvas ref={canvasRef}></canvas>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}

      {/* Modal for Correct Answer Prompt */}
      <Modal
        show={showPromptModal}
        onHide={() => setShowPromptModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Enter Correct Answer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitCorrectAnswer}>
            {questionType === 'multiple_choice' && (
              <Form.Group controlId="correctAnswer">
                <Form.Label>Select Correct Option</Form.Label>
                <Form.Control
                  as="select"
                  value={correctAnswer}
                  onChange={(e) => setCorrectAnswer(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                </Form.Control>
              </Form.Group>
            )}
            {questionType === 'yes_no' && (
              <Form.Group controlId="correctAnswer">
                <Form.Label>Select Correct Option</Form.Label>
                <Form.Control
                  as="select"
                  value={correctAnswer}
                  onChange={(e) => setCorrectAnswer(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Form.Group>
            )}
            {questionType === 'short_input' && (
              <Form.Group controlId="correctAnswer">
                <Form.Label>Enter Correct Answer</Form.Label>
                <Form.Control
                  type="text"
                  value={correctAnswer}
                  onChange={(e) => setCorrectAnswer(e.target.value)}
                />
              </Form.Group>
            )}
            <Modal.Footer>
              {/* Removed the Cancel button to force user to enter correct answer */}
              <button
                type="submit"
                className="btn custom-btn"
                disabled={!correctAnswer}
              >
                Submit
              </button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ManageSession;
