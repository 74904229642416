// src/components/student/ClassDetails.js

import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { getDatabase, ref, get } from 'firebase/database';
import Preloader from '../Preloader';
import StudentStats from './StudentStats';
import StudentSessionList from './StudentSessionList';
import StudentGrades from './StudentGrades';

const ClassDetails = ({ selectedClass, closeClass, user }) => {
  const [loading, setLoading] = useState(true);
  const [grades, setGrades] = useState({});
  const [sessions, setSessions] = useState([]);
  const [classDetails, setClassDetails] = useState(null);
  const [accessPermissions, setAccessPermissions] = useState({});
  const [activeTab, setActiveTab] = useState('stats');

  const fetchClassDetails = async () => {
    setLoading(true);
    try {
      // Fetch class details
      const classDocRef = doc(db, 'classes', selectedClass.id);
      const classDocSnap = await getDoc(classDocRef);
      if (classDocSnap.exists()) {
        const classData = classDocSnap.data();
        setClassDetails(classData);

        // Set access permissions from class data
        setAccessPermissions({
          statsaccess: classData.statsaccess,
          gradeaccess: classData.gradeaccess,
          sessionaccess: classData.sessionaccess,
        });
      }

      // Fetch student's grades
      const gradesDocRef = doc(db, 'grades', selectedClass.id);
      const gradesDocSnap = await getDoc(gradesDocRef);
      if (gradesDocSnap.exists()) {
        const studentGrades = gradesDocSnap.data().students?.[user.uid] || {};
        setGrades(studentGrades);
      } else {
        setGrades({});
      }

      // Fetch sessions
      const dbRealtime = getDatabase();
      const sessionsRef = ref(dbRealtime, `sessions/${selectedClass.id}`);
      const sessionsSnapshot = await get(sessionsRef);
      if (sessionsSnapshot.exists()) {
        const sessionsData = sessionsSnapshot.val();
        setSessions(
          Object.entries(sessionsData).map(([id, data]) => ({ id, ...data }))
        );
      } else {
        setSessions([]);
      }
    } catch (error) {
      console.error('Error fetching class details:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClassDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Preloader />;
  }

  // Check which tabs to display based on access permissions
  const { statsaccess, gradeaccess, sessionaccess } = accessPermissions;

  // If no access permissions are true, display message
  if (!statsaccess && !gradeaccess && !sessionaccess) {
    return (
      <div className="class-details">
        <button className="btn btn-secondary close-btn" onClick={closeClass}>
          &larr; Back to Classes
        </button>
        <h2>{classDetails?.class_name || 'Class Name'}</h2>
        <p>Professor has not enabled access for this class.</p>
      </div>
    );
  }

  return (
    <div className="class-details container mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>{classDetails?.class_name || 'Class Name'}</h2>
        <button onClick={closeClass} className="btn btn-danger">
          &times;
        </button>
      </div>

      {/* Bootstrap Nav Tabs */}
      <ul className="nav nav-tabs" role="tablist">
        {statsaccess && (
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 'stats' ? 'active' : ''}`}
              onClick={() => setActiveTab('stats')}
              role="tab"
              href="#!"
            >
              Stats
            </a>
          </li>
        )}
        {gradeaccess && (
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 'grades' ? 'active' : ''}`}
              onClick={() => setActiveTab('grades')}
              role="tab"
              href="#!"
            >
              Grades
            </a>
          </li>
        )}
        {sessionaccess && (
          <li className="nav-item">
            <a
              className={`nav-link ${activeTab === 'sessions' ? 'active' : ''}`}
              onClick={() => setActiveTab('sessions')}
              role="tab"
              href="#!"
            >
              Sessions
            </a>
          </li>
        )}
      </ul>

      <div className="tab-content mt-3">
        {activeTab === 'stats' && statsaccess && (
          <div className="tab-pane fade show active" role="tabpanel">
            <StudentStats
              grades={grades}
              sessions={sessions}
              classId={selectedClass.id}
              user={user}
            />
          </div>
        )}
        {activeTab === 'grades' && gradeaccess && (
          <div className="tab-pane fade show active" role="tabpanel">
            <StudentGrades
              grades={grades}
              sessions={sessions}
              classId={selectedClass.id}
              user={user}
            />
          </div>
        )}
        {activeTab === 'sessions' && sessionaccess && (
          <div className="tab-pane fade show active" role="tabpanel">
            <StudentSessionList
              sessions={sessions}
              classId={selectedClass.id}
              user={user}
              grades={grades}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ClassDetails;
