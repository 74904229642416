import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

// Import your components and pages
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import ProductDetail from './pages/ProductDetail';
import Contact from './pages/Contact';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import StudentDashboard from './pages/Student/StudentDashboard';
import ProfessorDashboard from './pages/Professor/ProfessorDashboard';
import ProfessorDocumentation from './pages/Professor/ProfessorDocumentation';
import ProfessorSupport from './pages/Professor/ProfessorSupport';
import StudentResults from './pages/Student/StudentResults';
import StudentSettings from './pages/Student/StudentSettings';
import AdminSupport from './pages/Admin/AdminSupport'; // Import AdminSupport
import NotFound from './pages/NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider

function AppWrapper() {
  const location = useLocation();

  // Boolean to control the visibility of the banner
  const [showBanner, setShowBanner] = useState(true); // Initialize as true to show the banner

  // Define routes where the banner should not appear
  const hideBannerRoutes = [
    '/student',
    '/student-results',
    '/student-settings',
    '/professor',
    '/professor-documentation',
    '/professor-support',
    '/admin-support', // Add admin support to hide banner
  ];

  // Determine if the current route is in the hideBannerRoutes list
  const shouldHideBanner = hideBannerRoutes.includes(location.pathname);

  return (
    <>
      {/* Conditionally render the banner based on showBanner and current route */}
      {!shouldHideBanner && showBanner && (
        <div
          className="alert alert-warning alert-dismissible fade show text-center fixed-top m-0"
          role="alert"
          style={{ zIndex: 1050 }}
        >
          <strong>Testing Phase:</strong> This is a testing phase. Please contact{' '}
          <a href="mailto:info@coursengage.com" className="alert-link">
            info@coursengage.com
          </a>{' '}
          to participate, and we will send you the innovation code for sign up. All functionalities are currently not working.
          {/* Close Button */}
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => setShowBanner(false)}
          ></button>
        </div>
      )}

      {/* Add padding-top to prevent content from being hidden behind the fixed banner */}
      {/* Estimate the height of the banner; adjust 'pt-5' as needed */}
      <div className={!shouldHideBanner && showBanner ? 'pt-5' : ''}>
        {/* Conditionally render Navbar */}
        {!hideBannerRoutes.includes(location.pathname) && <Navbar />}

        {/* Define Routes */}
        <Routes>
          {/* Public Routes (Accessible without sign-in) */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/product-detail" element={<ProductDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />

          {/* Protected Routes (Require sign-in) */}

          {/* Student Routes */}
          <Route
            path="/student"
            element={
              <ProtectedRoute requiredRole="student">
                <StudentDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student-results"
            element={
              <ProtectedRoute requiredRole="student">
                <StudentResults />
              </ProtectedRoute>
            }
          />
          <Route
            path="/student-settings"
            element={
              <ProtectedRoute requiredRole="student">
                <StudentSettings />
              </ProtectedRoute>
            }
          />

          {/* Professor Routes */}
          <Route
            path="/professor"
            element={
              <ProtectedRoute requiredRole="professor">
                <ProfessorDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/professor-documentation"
            element={
              <ProtectedRoute requiredRole="professor">
                <ProfessorDocumentation />
              </ProtectedRoute>
            }
          />
          <Route
            path="/professor-support"
            element={
              <ProtectedRoute requiredRole="professor">
                <ProfessorSupport />
              </ProtectedRoute>
            }
          />

          {/* Admin Routes */}
          <Route
            path="/admin-support"
            element={
              <ProtectedRoute requiredRole="admin">
                <AdminSupport />
              </ProtectedRoute>
            }
          />

          {/* Catch-all route for undefined paths */}
          <Route path="*" element={<NotFound />} />
        </Routes>

        {/* Conditionally render Footer */}
        {!hideBannerRoutes.includes(location.pathname) && <Footer />}
      </div>
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppWrapper />
      </Router>
    </AuthProvider>
  );
}

export default App;
