// Contact.js
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [isLoading, setIsLoading] = useState(false); // State variable for spinner

  const sendEmail = async (e) => {
    e.preventDefault();

    setIsLoading(true); // Show spinner when form is submitted

    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

    const templateParams = {
      from_name: formData.name,
      subject: formData.subject,
      message: formData.message,
      email: formData.email,
      reply_to: 'debugger.developers@gmail.com',
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams
      )
      .then(
        (response) => {
          alert('Thank you for reaching out! We will get back to you shortly.');
          setIsLoading(false); // Hide spinner after success
          setFormData({
            name: '',
            email: '',
            subject: '',
            message: '',
          }); // Reset form fields if desired
        },
        (error) => {
          alert('FAILED... ' + JSON.stringify(error));
          setIsLoading(false); // Hide spinner after failure
        }
      );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      {/* Spinner Component */}
      {isLoading && (
        <section className="preloader">
          <div className="spinner">
            <span className="sk-inner-circle"></span>
          </div>
        </section>
      )}

      <main>
        <section className="contact section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <h2 className="mb-4">
                  Let's <span>begin</span>
                </h2>

                <form className="contact-form me-lg-5 pe-lg-3" onSubmit={sendEmail}>
                  <div className="form-floating">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      placeholder="Full name"
                      required
                      value={formData.name}
                      onChange={handleChange}
                    />
                    <label htmlFor="name">Full name</label>
                  </div>

                  <div className="form-floating my-4">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      pattern="[^ @]*@[^ @]*"
                      className="form-control"
                      placeholder="Email address"
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <label htmlFor="email">Email address</label>
                  </div>

                  <div className="form-floating my-4">
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      className="form-control"
                      placeholder="Subject"
                      required
                      value={formData.subject}
                      onChange={handleChange}
                    />
                    <label htmlFor="subject">Subject</label>
                  </div>

                  <div className="form-floating mb-4">
                    <textarea
                      id="message"
                      name="message"
                      className="form-control"
                      placeholder="Leave a comment here"
                      required
                      style={{ height: '160px' }}
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                    <label htmlFor="message">Tell us about the project</label>
                  </div>

                  <div className="col-lg-5 col-6">
                    <button type="submit" className="form-control">
                      Send
                    </button>
                  </div>
                </form>
              </div>

              <div className="col-lg-6 col-12 mt-5 ms-auto">
                <div className="row">
                  <div className="col-6 border-end contact-info">
                    <h6 className="mb-3">More info?</h6>

                    <a href="mailto:info@coursengage.com" className="custom-link">
                    info@coursengage.com
                      <i className="bi-arrow-right ms-2"></i>
                    </a>
                  </div>

                  <div className="col-6 contact-info">
                    <h6 className="mb-3">Karnav Patel (Founder/CEO)</h6>

                    <a href="mailto:karnav@coursengage.com" className="custom-link">
                    karnav@coursengage.com
                      <i className="bi-arrow-right ms-2"></i>
                    </a>
                  </div>

                  <div className="col-6 border-top border-end contact-info">
                    <h6 className="mb-3">Our Office</h6>

                    <p className="text-muted">Lincoln, NE 68508, United States</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Contact;
