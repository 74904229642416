// src/components/professor/ClassList.js
import React, { useState, useEffect } from 'react';
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  setDoc,
  serverTimestamp,
  doc,
  getDoc,
} from 'firebase/firestore';
import { db } from '../../../firebase';
import ClassFormModal from './ClassFormModal';
import Preloader from '../../Preloader';

const ClassList = ({ selectClass, user }) => {
  const [classes, setClasses] = useState([]);
  const [showClassModal, setShowClassModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchClasses = async () => {
    setLoading(true);
    try {
      const q = query(
        collection(db, 'classes'),
        where('professor_uid', '==', user.uid)
      );
      const querySnapshot = await getDocs(q);
      const classesData = [];

      for (const docSnap of querySnapshot.docs) {
        const classData = docSnap.data();
        const enrollmentCount = await getEnrollmentCount(docSnap.id);
        classesData.push({
          id: docSnap.id,
          ...classData,
          enrollmentCount,
        });
      }

      setClasses(classesData);
    } catch (error) {
      console.error('Error fetching classes:', error);
    } finally {
      setLoading(false);
    }
  };

  const getEnrollmentCount = async (classId) => {
    try {
      const enrollmentsDoc = await getDoc(doc(db, 'enrollments', classId));
      if (enrollmentsDoc.exists()) {
        return enrollmentsDoc.data().students.length;
      }
      return 0;
    } catch (error) {
      console.error(`Error getting enrollment count for class ${classId}:`, error);
      return 0;
    }
  };

  useEffect(() => {
    fetchClasses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddClass = async (newClass) => {
    setLoading(true);
    try {
      const classInvitation = generateInvitationCode();

      const classRef = await addDoc(collection(db, 'classes'), {
        class_name: newClass.class_name,
        professor_uid: user.uid,
        start_date: new Date(newClass.start_date),
        end_date: new Date(newClass.end_date),
        class_invitation: classInvitation,
        created_at: serverTimestamp(),
      });

      // Initialize enrollments and grades for the new class
      await setDoc(doc(db, 'enrollments', classRef.id), { students: [] });
      await setDoc(doc(db, 'grades', classRef.id), {});

      // Refresh classes
      await fetchClasses();

      // Close modal
      setShowClassModal(false);
    } catch (error) {
      console.error('Error adding class:', error);
    } finally {
      setLoading(false);
    }
  };

  const generateInvitationCode = () => {
    return Math.random().toString(36).substring(2, 8).toUpperCase();
  };

  const handleCopyInvitation = (invitationCode) => {
    navigator.clipboard
      .writeText(invitationCode)
      .then(() => {
        alert('Invitation code copied to clipboard!');
      })
      .catch((error) => {
        console.error('Failed to copy invitation code:', error);
      });
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <>
      <h1>Class Section</h1>
      <div className="class-cards">
        {classes.length === 0 ? (
          <p>No classes found for this professor.</p>
        ) : (
          classes.map((cls) => (
            <div
              key={cls.id}
              className="class-card"
              onClick={() => selectClass(cls)}
            >
              <h3>{cls.class_name}</h3>
              <p>Enrollments: {cls.enrollmentCount}</p>
              <p>
                Invitation Code: <span>{cls.class_invitation}</span>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyInvitation(cls.class_invitation);
                  }}
                  className="btn copy-btn"
                >
                  <i className="bi bi-clipboard"></i> Copy
                </button>
              </p>
            </div>
          ))
        )}
      </div>
      <button onClick={() => setShowClassModal(true)} className="btn custom-btn">
        Add Class
      </button>

      {/* Class Form Modal */}
      {showClassModal && (
        <ClassFormModal
          closeModal={() => setShowClassModal(false)}
          handleAddClass={handleAddClass}
        />
      )}
    </>
  );
};

export default ClassList;
