import React from 'react';

function PrivacyPolicy() {
  return (
    <section className="privacy-policy section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto col-12">
            <h1 className="hero-title text-center mb-5">Privacy Policy</h1>

            <p>
              At <strong>CoursEngage</strong>, we are committed to safeguarding your privacy and ensuring that your personal information is protected. This Privacy Policy outlines how we collect, use, and protect your data when you interact with us online or through our services.
            </p>
            
            <h2>1. Information We Collect</h2>
            <p>
              We collect personal information such as your name, email address, and password when you register or use our services.
            </p>
            
            <h2>2. How We Use Your Information</h2>
            <p>
              Your personal information is used to provide you with our services, improve user experience, and communicate updates or offers. We do not share your personal data with third parties under any circumstances.
            </p>
            
            <h2>3. Data Protection</h2>
            <p>
              We implement a variety of security measures to maintain the safety of your personal information. Your data is stored in secured networks, and access is limited to authorized personnel only.
            </p>

            <h2>4. Your Rights</h2>
            <p>
              You have the right to access, modify, or delete your personal information at any time. If you choose to delete your account, all associated data will be permanently removed from our systems. For any questions regarding the management of your data, please contact us.
            </p>
            
            <h2>5. Changes to This Policy</h2>
            <p>
              We may update this Privacy Policy periodically to reflect changes in our practices. Any updates will be posted on this page, and we encourage you to review our policy regularly.
            </p>
            
            <h2>6. Contact Us</h2>
            <p>
              If you have any questions regarding this Privacy Policy, please contact us at <strong>info@coursengage.com</strong>.
            </p>
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
