import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

const StudentGradesModal = ({ student, gradesData, sessionsData, closeModal, classId }) => {
  // Access the student's grades from the grades data
  const studentGrades = gradesData?.students?.[student.uid] || {};

  const [expandedSessions, setExpandedSessions] = useState({});
  const [editing, setEditing] = useState(null); // { sessionId, questionNumber }
  const [editedPoint, setEditedPoint] = useState(null);

  const toggleSession = (sessionId) => {
    setExpandedSessions((prev) => ({
      ...prev,
      [sessionId]: !prev[sessionId],
    }));
  };

  const handleEditClick = (sessionId, questionNumber, currentPoint) => {
    setEditing({ sessionId, questionNumber });
    setEditedPoint(currentPoint);
  };

  const handleSaveClick = async () => {
    const { sessionId, questionNumber } = editing;
    // Update the point in the gradesData and Firestore
    try {
      await updateDoc(doc(db, 'grades', classId), {
        [`students.${student.uid}.${sessionId}.points.${questionNumber}`]: Number(editedPoint),
      });
      // Update local state
      gradesData.students[student.uid][sessionId].points[questionNumber] = Number(editedPoint);
      setEditing(null);
      setEditedPoint(null);
    } catch (error) {
      console.error('Error updating grade:', error);
    }
  };

  const handleCancelClick = () => {
    setEditing(null);
    setEditedPoint(null);
  };

  const totalPoints = Object.values(studentGrades).reduce(
    (sum, session) =>
      sum + Object.values(session.points || {}).reduce((s, p) => s + p, 0),
    0
  );

  // Prepare sessions list
  const sessionEntries = sessionsData
    ? Array.isArray(sessionsData)
      ? sessionsData.map((session) => [session.id, session])
      : Object.entries(sessionsData)
    : [];

  return (
    <div className="modal show" tabIndex="-1" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-scrollable modal-lg">
        <div className="modal-content">

          <h5 className="modal-title">
            Grades for {student.firstName} {student.lastName} (Total Points: {totalPoints})
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={closeModal}
            style={{
              position: 'absolute',
              right: '16px',
              top: '16px',
            }}
          ></button>

          {/* Modal Body */}
          <div className="modal-body">
            <table className="table table-hover">
              <thead className="table-light">
                <tr>
                  <th>Session Date</th>
                  <th>Total Points</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {sessionEntries.map(([sessionId, session]) => {
                  const sessionData = studentGrades[sessionId];

                  const sessionDate = session?.start_date_time
                    ? new Date(session.start_date_time).toLocaleDateString()
                    : sessionId;

                  const sessionTotalPoints = sessionData
                    ? Object.values(sessionData.points || {}).reduce((sum, p) => sum + p, 0)
                    : 'Not attended';

                  const isExpanded = expandedSessions[sessionId];

                  return (
                    <React.Fragment key={sessionId}>
                      <tr className="table-primary">
                        <td>{sessionDate}</td>
                        <td>{sessionTotalPoints}</td>
                        <td>
                          {sessionData ? (
                            <button
                              className="btn btn-sm btn-outline-primary"
                              onClick={() => toggleSession(sessionId)}
                            >
                              {isExpanded ? 'Collapse' : 'Expand'}
                            </button>
                          ) : (
                            'N/A'
                          )}
                        </td>
                      </tr>
                      {isExpanded &&
                        sessionData &&
                        Object.entries(sessionData.points || {}).map(
                          ([questionNumber, point]) => (
                            <tr key={`${sessionId}-${questionNumber}`}>
                              <td colSpan="1" className="ps-5">
                                Question {questionNumber}
                              </td>
                              <td>
                                {editing &&
                                editing.sessionId === sessionId &&
                                editing.questionNumber === questionNumber ? (
                                  <input
                                    type="number"
                                    value={editedPoint}
                                    onChange={(e) => setEditedPoint(e.target.value)}
                                    className="form-control form-control-sm"
                                  />
                                ) : (
                                  point
                                )}
                              </td>
                              <td>
                                {editing &&
                                editing.sessionId === sessionId &&
                                editing.questionNumber === questionNumber ? (
                                  <>
                                    <button
                                      className="btn btn-sm btn-success me-2"
                                      onClick={handleSaveClick}
                                    >
                                      Save
                                    </button>
                                    <button
                                      className="btn btn-sm btn-secondary"
                                      onClick={handleCancelClick}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-sm btn-warning"
                                    onClick={() =>
                                      handleEditClick(sessionId, questionNumber, point)
                                    }
                                  >
                                    Edit
                                  </button>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Modal Footer */}
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={closeModal}>
              Close
            </button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default StudentGradesModal;
