import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ children, requiredRole }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    // Optionally, render a loader while checking auth state
    return null; // Or a loading spinner/component
  }

  if (!user.isAuthenticated) {
    // Redirect unauthenticated users to the sign-in page
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  if (requiredRole && user.role !== requiredRole) {
    // Redirect users with insufficient permissions
    return <Navigate to="/" replace />;
  }

  // If authenticated and has the required role, render the child component
  return children;
};

export default ProtectedRoute;
