import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase';
import {
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import {
  doc,
  deleteDoc,
  writeBatch,
  collection,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import Navbar from '../../components/student/NavBar';
import Preloader from '../../components/Preloader';

const StudentSettings = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Check if user is signed in
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        console.log('No user is signed in.');
        window.location.href = '/sign-in'; // Redirect to sign-in if not authenticated
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribeAuth();
  }, []);

  const handleResetPassword = async () => {
    setLoading(true);
    try {
      if (user && user.email) {
        await sendPasswordResetEmail(auth, user.email);
        alert('Password reset email sent. Please check your inbox.');
      } else {
        alert('No authenticated user found.');
      }
    } catch (error) {
      console.error('Error sending password reset email:', error);
      alert('Failed to send password reset email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (
      window.confirm(
        'Are you sure you want to delete your account? This action cannot be undone.'
      )
    ) {
      const password = prompt('Please enter your password to confirm account deletion:');
      if (!password) {
        alert('Password is required to delete account.');
        return;
      }

      setLoading(true);
      try {
        if (user && user.email) {
          // Reauthenticate user
          await signInWithEmailAndPassword(auth, user.email, password);

          // Delete related data: enrollments, grades
          const batch = writeBatch(db);
          const collectionsToDelete = ['enrollments', 'grades'];

          for (const collectionName of collectionsToDelete) {
            const q = query(
              collection(db, collectionName),
              where('student_uid', '==', user.uid)
            );
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((docSnap) => {
              batch.delete(doc(db, collectionName, docSnap.id));
            });
          }

          await batch.commit();

          // Delete user settings
          await deleteDoc(doc(db, 'users', user.uid));

          // Delete user from Firebase Auth
          await user.delete();

          alert('Account deleted successfully.');
          window.location.href = '/sign-up';
        } else {
          alert('No authenticated user found.');
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        alert('Failed to delete account. Please ensure your password is correct and try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        window.location.href = '/sign-in'; // Redirect to sign-in after logging out
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  return (
    <>
      <Navbar user={user} handleSignOut={handleSignOut} />
      <main>
        <section className="main-content section-padding">
          <div className="text-center">
            {loading && <Preloader />}
            <h1>Student Settings</h1>

            {/* Account Security */}
            <div className="settings-group">
              <h3>Account Security</h3>
              <button onClick={handleResetPassword} className="btn custom-btn">
                Reset Password
              </button>
            </div>

            {/* Account Management */}
            <div className="settings-group">
              <h3>Account Management</h3>
              <button onClick={handleDeleteAccount} className="btn custom-btn delete-btn">
                Delete Account
              </button>
            </div>

            {/* Sign Out */}
            <div className="settings-group">
              <button onClick={handleSignOut} className="btn custom-btn signout-btn">
                Sign Out
              </button>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default StudentSettings;
