// src/components/student/StudentGrades.js

import React, { useState } from 'react';

const StudentGrades = ({ grades, sessions }) => {
  const [expandedSessions, setExpandedSessions] = useState({});

  const toggleSession = (sessionId) => {
    setExpandedSessions((prev) => ({
      ...prev,
      [sessionId]: !prev[sessionId],
    }));
  };

  // Compute overall total points
  const totalPoints = Object.values(grades).reduce(
    (sum, session) =>
      sum + Object.values(session.points || {}).reduce((s, p) => s + p, 0),
    0
  );

  // Prepare sessions list with dates
  const sessionEntries = sessions
    ? sessions.map((session) => [session.id, session])
    : [];

  return (
    <div className="grades-tab">
      <h3>Your Grades</h3>
      <h5>Total Points: {totalPoints}</h5>
      <table className="table table-hover">
        <thead className="table-light">
          <tr>
            <th>Session Date</th>
            <th>Session Total Points</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {sessionEntries.map(([sessionId, session]) => {
            const sessionData = grades[sessionId];

            const sessionDate = session?.start_date_time
              ? new Date(session.start_date_time).toLocaleDateString()
              : 'Unknown Date';

            const sessionTotalPoints = sessionData
              ? Object.values(sessionData.points || {}).reduce(
                  (sum, p) => sum + p,
                  0
                )
              : 'Not attended';

            const isExpanded = expandedSessions[sessionId];

            return (
              <React.Fragment key={sessionId}>
                <tr className="table-primary">
                  <td>{sessionDate}</td>
                  <td>{sessionTotalPoints}</td>
                  <td>
                    {sessionData ? (
                      <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => toggleSession(sessionId)}
                      >
                        {isExpanded ? 'Collapse' : 'Expand'}
                      </button>
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
                {isExpanded &&
                  sessionData &&
                  Object.entries(sessionData.points || {}).map(
                    ([questionNumber, point]) => (
                      <tr key={`${sessionId}-${questionNumber}`}>
                        <td className="ps-5">Question {questionNumber}</td>
                        <td>{point}</td>
                        <td></td>
                      </tr>
                    )
                  )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default StudentGrades;
