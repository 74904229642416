// src/pages/Student/StudentResults.js

import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import Navbar from '../../components/student/NavBar';
import ClassDetails from '../../components/student/ClassDetails'; // Import the new ClassDetails
import Preloader from '../../components/Preloader';

function StudentResults() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);

  useEffect(() => {
    // Check if user is signed in
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        loadStudentClasses(currentUser);
      } else {
        console.log('No user is signed in.');
        window.location.href = '/sign-in';
      }
    });

    // Cleanup on unmount
    return () => {
      unsubscribeAuth();
    };
  }, []);

  const loadStudentClasses = async (currentUser) => {
    try {
      const classCards = [];
      const currentUserUid = currentUser.uid;

      // Query enrollments where students array contains currentUserUid
      const enrollmentsRef = collection(db, 'enrollments');
      const q = query(
        enrollmentsRef,
        where('students', 'array-contains', currentUserUid)
      );
      const querySnapshot = await getDocs(q);

      for (const enrollmentDoc of querySnapshot.docs) {
        const classId = enrollmentDoc.id;
        const classDocRef = doc(db, 'classes', classId);
        const classDocSnap = await getDoc(classDocRef);

        if (classDocSnap.exists()) {
          const classData = classDocSnap.data();
          classCards.push({
            id: classId, // Change to 'id' to match the prop used in ClassDetails
            ...classData,
          });
        }
      }

      setClasses(classCards);
      setLoading(false);
    } catch (error) {
      console.error('Error loading student classes:', error);
      setLoading(false);
    }
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        window.location.href = '/sign-in';
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <>
      <Navbar user={user} handleSignOut={handleSignOut} />
      <main>
        <section className="main-content section-padding">
          {selectedClass ? (
            <ClassDetails
              selectedClass={selectedClass}
              closeClass={() => setSelectedClass(null)}
              user={user}
            />
          ) : (
            <>
              <div id="class-cards" className="container">
                {classes.map((classItem) => (
                  <div
                    key={classItem.id}
                    className="class-card"
                    onClick={() => setSelectedClass(classItem)}
                  >
                    <h3>{classItem.class_name}</h3>
                    <p>Invitation Code: {classItem.class_invitation}</p>
                  </div>
                ))}
              </div>
              {classes.length === 0 && (
                <div className="no-classes">
                  <p>You are not enrolled in any classes.</p>
                </div>
              )}
            </>
          )}
        </section>
      </main>
    </>
  );
}

export default StudentResults;
