// src/pages/ProfessorSupport.js
import React, { useState, useEffect, useRef } from 'react';
import { auth, db, storage } from '../../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import Navbar from '../../components/professor/NavBar';
import Preloader from '../../components/Preloader';
import {
  collection,
  doc,
  query,
  where,
  orderBy,
  onSnapshot,
  addDoc,
  serverTimestamp,
  getDoc,
  deleteDoc,
  getDocs,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { FaPaperclip } from 'react-icons/fa'; // Import the attachment icon

// Import the createTicket function
import { createTicket } from '../../api/ticketApi';

function ProfessorSupport() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    priority: 'Low',
    attachments: [],
  });
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [currentTicket, setCurrentTicket] = useState(null); // Store the ticket data
  const [messages, setMessages] = useState([]); // Store messages
  const [newMessage, setNewMessage] = useState(''); // New message input
  const [view, setView] = useState('list'); // 'list', 'chat', 'form'
  const [messagesUnsubscribe, setMessagesUnsubscribe] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // For image attachment in chat
  const [modalImageUrl, setModalImageUrl] = useState(null); // For displaying full-size images

  // Refs for file inputs
  const attachmentInputRef = useRef(null);
  const chatAttachmentInputRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setFormData((prev) => ({
          ...prev,
          email: currentUser.email,
        }));
      } else {
        window.location.href = '/sign-in';
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Fetch user's tickets
  useEffect(() => {
    if (user) {
      const ticketsCollectionRef = collection(db, 'tickets');
      const q = query(
        ticketsCollectionRef,
        where('userId', '==', user.uid),
        orderBy('createdAt', 'desc')
      );

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const userTickets = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setTickets(userTickets);
      });

      return () => unsubscribe();
    }
  }, [user]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      window.location.href = '/sign-in';
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'attachments') {
      setFormData((prev) => ({
        ...prev,
        attachments: files ? Array.from(files) : [],
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleAttachmentClick = () => {
    attachmentInputRef.current.click();
  };

  const handleChatAttachmentClick = () => {
    chatAttachmentInputRef.current.click();
  };

  const handleChatAttachmentChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmissionStatus(null);
    setSubmitting(true);

    try {
      // Call the createTicket function with formData
      const ticketNumber = await createTicket({
        ...formData,
      });

      setSubmissionStatus(
        `Ticket submitted successfully! Your ticket number is ${ticketNumber}.`
      );

      // Reset the form data
      setFormData({
        name: '',
        email: user.email, // keep the logged-in user’s email
        subject: '',
        message: '',
        priority: 'Low',
        attachments: [],
      });

      // Fetch the new ticket data from Firestore
      const ticketDocRef = doc(db, 'tickets', ticketNumber);
      const ticketSnapshot = await getDoc(ticketDocRef);
      if (ticketSnapshot.exists()) {
        const newTicket = { id: ticketNumber, ...ticketSnapshot.data() };
        setCurrentTicket(newTicket);
        openTicket(newTicket);
      } else {
        console.error('Ticket not found after creation.');
      }
    } catch (error) {
      console.error('Error submitting ticket:', error);
      setSubmissionStatus(error.message || 'Error submitting the ticket. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const openTicket = (ticket) => {
    setCurrentTicket(ticket);
    setView('chat');

    // Set up real-time listener for messages
    const ticketDocRef = doc(db, 'tickets', ticket.id);
    const messagesCollectionRef = collection(ticketDocRef, 'messages');
    const q = query(messagesCollectionRef, orderBy('timestamp', 'asc'));

    // Clean up previous listener
    if (messagesUnsubscribe) {
      messagesUnsubscribe();
    }

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const msgs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setMessages(msgs);
    });

    setMessagesUnsubscribe(() => unsubscribe);
  };

  const sendMessage = async () => {
    if (newMessage.trim() === '' && !selectedImage) return;
    if (!currentTicket) return;

    const messageData = {
      sender: 'user',
      messageText: newMessage,
      timestamp: serverTimestamp(),
      userId: user.uid, // Include userId for security rules
    };

    try {
      if (selectedImage) {
        // Upload the image to Firebase Storage
        const imageRef = ref(
          storage,
          `tickets/${currentTicket.id}/messages/${Date.now()}_${selectedImage.name}`
        );
        const snapshot = await uploadBytes(imageRef, selectedImage);
        const downloadURL = await getDownloadURL(snapshot.ref);

        messageData.imageUrl = downloadURL;
        messageData.imageName = selectedImage.name;
      }

      const ticketDocRef = doc(db, 'tickets', currentTicket.id);
      const messagesCollectionRef = collection(ticketDocRef, 'messages');
      await addDoc(messagesCollectionRef, messageData);

      setNewMessage(''); // Clear the input field
      setSelectedImage(null); // Clear the selected image
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const deleteTicket = async (ticketId) => {
    if (
      window.confirm(
        'Are you sure you want to delete this ticket? This action cannot be undone.'
      )
    ) {
      try {
        const ticketDocRef = doc(db, 'tickets', ticketId);

        // Check if the current user is the owner of the ticket
        const ticketDoc = await getDoc(ticketDocRef);
        if (ticketDoc.exists() && ticketDoc.data().userId === user.uid) {
          // Delete attachments associated with the ticket
          const ticketData = ticketDoc.data();

          if (ticketData.attachment && ticketData.attachment.length > 0) {
            const deleteAttachmentPromises = ticketData.attachment.map(async (url) => {
              try {
                const fileRef = ref(storage, url);
                await deleteObject(fileRef);
              } catch (error) {
                console.error('Error deleting attachment from storage:', error);
              }
            });
            await Promise.all(deleteAttachmentPromises);
          }

          // Delete all messages in the subcollection and their attachments
          const messagesCollectionRef = collection(ticketDocRef, 'messages');
          const messagesSnapshot = await getDocs(messagesCollectionRef);
          const deleteMessagePromises = messagesSnapshot.docs.map(async (messageDoc) => {
            const messageData = messageDoc.data();

            // Delete any attachments in messages
            if (messageData.imageUrl) {
              try {
                const imageRef = ref(storage, messageData.imageUrl);
                await deleteObject(imageRef);
              } catch (error) {
                console.error('Error deleting message attachment from storage:', error);
              }
            }

            // Delete the message document
            await deleteDoc(messageDoc.ref);
          });
          await Promise.all(deleteMessagePromises);

          // Delete the ticket document
          await deleteDoc(ticketDocRef);

          // Update the tickets list
          setTickets(tickets.filter((ticket) => ticket.id !== ticketId));

          // If the deleted ticket was open, close the view
          if (currentTicket && currentTicket.id === ticketId) {
            setCurrentTicket(null);
            setView('list');
          }

          // Optionally, display a success message
        } else {
          alert('You are not authorized to delete this ticket.');
        }
      } catch (error) {
        console.error('Error deleting ticket:', error);
        alert('An error occurred while deleting the ticket. Please try again.');
      }
    }
  };


  const openImageModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };

  const closeImageModal = () => {
    setModalImageUrl(null);
  };

  const goBack = () => {
    if (messagesUnsubscribe) {
      messagesUnsubscribe();
      setMessagesUnsubscribe(null);
    }
    setCurrentTicket(null);
    setView('list');
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <>
      <Navbar userEmail={user.email} onSignOut={handleSignOut} />
      <section className="section-padding">
        <div className="container mt-4">
          {view === 'list' && (
            <>
              <h3>Your Tickets</h3>
              <button className="btn custom-btn mb-3" onClick={() => setView('form')}>
                Submit New Ticket
              </button>
              {tickets.length > 0 ? (
                <ul className="list-group">
                  {tickets.map((ticket) => (
                    <li
                      key={ticket.id}
                      className="list-group-item d-flex justify-content-between align-items-center"
                    >
                      <div
                        onClick={() => openTicket(ticket)}
                        style={{ cursor: 'pointer', flexGrow: 1 }}
                      >
                        <strong>{ticket.subject}</strong>
                        <br />
                        Status: {ticket.status} - Priority: {ticket.priority}
                      </div>
                      <button
                        className="btn custom-btn ms-2"
                        onClick={() => deleteTicket(ticket.id)}
                      >
                        Delete
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>You have no tickets.</p>
              )}
            </>
          )}

          {view === 'form' && (
            <div className="col-md-6 mx-auto">
              <h3 className="text-center mb-4">Submit a Support Ticket</h3>
              {submissionStatus && (
                <div
                  className={`alert ${submissionStatus.startsWith('Ticket submitted')
                    ? 'alert-success'
                    : 'alert-danger'
                    }`}
                  role="alert"
                >
                  {submissionStatus}
                </div>
              )}
              <form className="needs-validation" onSubmit={handleSubmit} noValidate>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="form-control"
                    placeholder="Full name"
                    required
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <label htmlFor="name">Full name</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder="Email address"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    disabled
                  />
                  <label htmlFor="email">Email address</label>
                </div>

                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    className="form-control"
                    placeholder="Subject"
                    required
                    value={formData.subject}
                    onChange={handleChange}
                  />
                  <label htmlFor="subject">Subject</label>
                </div>

                <div className="form-floating mb-3">
                  <textarea
                    id="message"
                    name="message"
                    className="form-control"
                    placeholder="Leave a comment here"
                    required
                    style={{ height: '100px' }}
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  <label htmlFor="message">Tell us about the issue</label>
                </div>

                <div className="form-floating mb-3">
                  <select
                    name="priority"
                    id="priority"
                    className="form-select"
                    value={formData.priority}
                    onChange={handleChange}
                  >
                    <option value="Low">Low</option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                  </select>
                  <label htmlFor="priority">Priority</label>
                </div>

                {/* Attachment Icon */}
                <div className="mb-3">
                  <label className="form-label">Attachments (optional):</label>
                  <div onClick={handleAttachmentClick} style={{ cursor: 'pointer' }}>
                    <FaPaperclip size={24} />
                    <span className="ms-2">Attach Files</span>
                  </div>
                  <input
                    type="file"
                    name="attachments"
                    id="attachments"
                    multiple
                    onChange={handleChange}
                    ref={attachmentInputRef}
                    style={{ display: 'none' }}
                  />
                  {/* Display selected file names */}
                  {formData.attachments && formData.attachments.length > 0 && (
                    <div className="mt-2">
                      {formData.attachments.map((file, index) => (
                        <div key={index}>{file.name}</div>
                      ))}
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn custom-btn w-100"
                  disabled={submitting}
                >
                  {submitting ? 'Submitting...' : 'Submit Ticket'}
                </button>
                <button
                  type="button"
                  className="btn custom-btn w-100 mt-2"
                  onClick={() => setView('list')}
                >
                  Cancel
                </button>
              </form>
            </div>
          )}

          {view === 'chat' && currentTicket && (
            <div>
              <button className="btn custom-btn mb-3" onClick={goBack}>
                Back to Tickets
              </button>
              <h3>Ticket Number: {currentTicket.ticketNumber || currentTicket.id}</h3>
              <p>Status: {currentTicket.status}</p>
              <p>
                <strong>Subject:</strong> {currentTicket.subject}
              </p>
              <p>
                <strong>Initial Message:</strong> {currentTicket.message}
              </p>

              {currentTicket.attachment && currentTicket.attachment.length > 0 && (
                <div>
                  <h5>Attachments</h5>
                  <div className="d-flex flex-wrap">
                    {currentTicket.attachment.map((url, index) => (
                      <div key={index} className="me-2 mb-2">
                        <img
                          src={url}
                          alt={`Attachment ${index + 1}`}
                          style={{ maxWidth: '100px', cursor: 'pointer' }}
                          onClick={() => openImageModal(url)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="card mb-3">
                <div className="card-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {messages.map((msg) => (
                    <div
                      key={msg.id}
                      className={`mb-2 ${msg.sender === 'admin' ? '' : 'text-end'}`}
                    >
                      <div
                        className={`p-2 rounded ${msg.sender === 'admin' ? 'bg-light' : 'bg-light'
                          }`}
                      >
                        {msg.imageUrl && (
                          <div className="d-flex flex-wrap">
                            <img
                              src={msg.imageUrl}
                              alt={msg.imageName || 'Image'}
                              style={{ maxWidth: '100px', cursor: 'pointer' }}
                              onClick={() => openImageModal(msg.imageUrl)}
                            />
                          </div>
                        )}
                        {msg.messageText && <small>{msg.messageText}</small>}
                        <br />
                        <small className="text-muted">
                          {msg.timestamp &&
                            new Date(msg.timestamp.seconds * 1000).toLocaleString()}
                        </small>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type your message..."
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
                {/* Attachment Icon */}
                <span
                  className="input-group-text"
                  onClick={handleChatAttachmentClick}
                  style={{ cursor: 'pointer' }}
                >
                  <FaPaperclip />
                </span>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleChatAttachmentChange}
                  ref={chatAttachmentInputRef}
                  style={{ display: 'none' }}
                />
                <button className="btn custom-btn" onClick={sendMessage}>
                  Send
                </button>
              </div>
              {/* Display selected image name */}
              {selectedImage && (
                <div className="mt-2">
                  Selected Image: {selectedImage.name}
                </div>
              )}

              {modalImageUrl && (
                <div
                  className="modal show"
                  style={{
                    display: 'block',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    overflow: 'auto',
                    zIndex: 1050, // Ensure the modal appears above other content
                  }}
                  onClick={closeImageModal}
                  aria-modal="true"
                  role="dialog"
                >
                  <div
                    className="modal-dialog modal-dialog-centered modal-xl"
                    style={{
                      maxWidth: '90vw',
                      maxHeight: '90vh',
                      marginTop: '50px', // Adjust top margin as needed
                      marginBottom: '50px', // Adjust bottom margin as needed
                    }}
                    onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
                  >
                    <div className="modal-content d-flex flex-column h-100">
                      <div className="modal-body d-flex justify-content-center align-items-center overflow-auto flex-grow-1">
                        <img
                          src={modalImageUrl}
                          alt="Full-size"
                          className="img-fluid"
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </div>
                      <div className="modal-footer">
                        <button className="btn btn-secondary" onClick={closeImageModal}>
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default ProfessorSupport;
