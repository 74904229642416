// src/components/professor/ClassSection.js
import React, { useState, useEffect } from 'react';
import { auth } from '../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import ClassList from './ClassSection/ClassList';
import ClassDetails from './ClassSection/ClassDetails';
import Preloader from '../Preloader';

const ClassSection = () => {
  const [selectedClass, setSelectedClass] = useState(null);
  const [loading, setLoading] = useState(true);
  const user = auth.currentUser;

  useEffect(() => {
    const initialize = () => {
      onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          setLoading(false);
        } else {
          window.location.href = '/signin';
        }
      });
    };

    initialize();
  }, []);

  if (loading) {
    return <Preloader />;
  }

  return (
    <section id="class-section" className="justify-content-center align-items-center text-center min-vh-100 mb-4 content-section active-section">
      {selectedClass ? (
        <ClassDetails
          selectedClass={selectedClass}
          closeClass={() => setSelectedClass(null)}
        />
      ) : (
        <ClassList selectClass={setSelectedClass} user={user} />
      )}
    </section>
  );
};

export default ClassSection;
