import React, { useState } from 'react';

function CostCalculator() {
  const [calculatedCost, setCalculatedCost] = useState('');

  const calculateCost = (e) => {
    e.preventDefault();
    const numStudents = parseInt(e.target.numStudents.value);
    const questionsPerClass = parseInt(e.target.questionsPerClass.value);
    const numClasses = parseInt(e.target.numClasses.value);
    const startDate = new Date(e.target.startDate.value);
    const endDate = new Date(e.target.endDate.value);

    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    const totalWeeks = Math.ceil((endDate - startDate) / oneWeek);

    if (totalWeeks <= 0) {
      setCalculatedCost('Please ensure the end date is after the start date.');
      return;
    }

    const responseChangesPerStudent = 5;
    const screenshotSizeMB = 1;

    const firestoreReadsWrites = numStudents * questionsPerClass * numClasses * 2 * responseChangesPerStudent;
    const realtimeDbStorageMB = numStudents * questionsPerClass * numClasses * 0.01 * responseChangesPerStudent;
    const screenshotStorageMB = screenshotSizeMB * questionsPerClass * numClasses;
    const realtimeDbDownloadMB = realtimeDbStorageMB * 0.5;

    const firestoreCost = Math.max(0, firestoreReadsWrites - 50000) / 100000 * 0.18;
    const realtimeDbCost = realtimeDbStorageMB / 1000 * 5 + realtimeDbDownloadMB / 1000 * 1;
    const screenshotCost = screenshotStorageMB / 1000 * 0.026;

    const weeklyCost = firestoreCost + realtimeDbCost + screenshotCost;
    const totalCost = weeklyCost * totalWeeks;

    setCalculatedCost(`Estimated CoursEngage Cost: $${totalCost.toFixed(2)} for ${totalWeeks} weeks`);
  };

  return (
    <section className="cost-calculator section-padding bg-light">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-5">
            <h2>Estimated Cost Calculator</h2>
            <p>Estimate your project costs based on student activity over a specified time period.</p>
          </div>

          <div className="col-lg-8 col-12 mx-auto">
            <form id="costCalculatorForm" onSubmit={calculateCost}>
              <div className="row mb-4">
                <div className="col-lg-6 col-12 mb-3">
                  <label htmlFor="numStudents" className="form-label">Number of Active Students</label>
                  <input type="number" id="numStudents" className="form-control" placeholder="Enter number of students" required />
                </div>
                <div className="col-lg-6 col-12 mb-3">
                  <label htmlFor="questionsPerClass" className="form-label">Questions per Class</label>
                  <input type="number" id="questionsPerClass" className="form-control" placeholder="Enter average number of questions" required />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-lg-6 col-12 mb-3">
                  <label htmlFor="numClasses" className="form-label">Number of Classes per Week</label>
                  <input type="number" id="numClasses" className="form-control" placeholder="Enter number of classes" required />
                </div>
                <div className="col-lg-6 col-12 mb-3">
                  <label htmlFor="startDate" className="form-label">Start Date</label>
                  <input type="date" id="startDate" className="form-control" required />
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-lg-6 col-12 mb-3">
                  <label htmlFor="endDate" className="form-label">End Date</label>
                  <input type="date" id="endDate" className="form-control" required />
                </div>
              </div>

              <div className="text-center">
                <button type="submit" className="btn custom-btn">Calculate Cost</button>
              </div>
            </form>

            <div className="mt-5 text-center" id="calculatedCost">
              {calculatedCost}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CostCalculator;
