// src/components/professor/SessionList.js
import React, { useState } from 'react';
import { db } from '../../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import {
    getDatabase,
    ref as refRealtime,
    get as getRealtime,
    update as updateRealtime,
} from 'firebase/database';
import Preloader from '../../Preloader';

const SessionList = ({ sessions, classId, fetchClassDetails }) => {
    const [sessionSearchTerm, setSessionSearchTerm] = useState('');
    const [expandedSessions, setExpandedSessions] = useState({});
    const [loading, setLoading] = useState(false);

    const handleSessionSearch = (e) => {
        setSessionSearchTerm(e.target.value);
    };

    const toggleSessionExpand = (sessionId) => {
        setExpandedSessions({
            ...expandedSessions,
            [sessionId]: !expandedSessions[sessionId],
        });
    };

    // Update the filteredSessions to handle date correctly
    const filteredSessions = sessions.filter((session) => {
        const sessionDate = new Date(session.start_date_time);
        return sessionDate
            .toLocaleDateString()
            .includes(sessionSearchTerm);
    });

    const updateCorrectAnswer = async (
        sessionId,
        questionNumber,
        newAnswer
    ) => {
        setLoading(true);
        try {
            // Update the correct answer in Realtime Database
            const dbRealtime = getDatabase();
            const sessionQuestionsRef = refRealtime(
                dbRealtime,
                `sessions/${classId}/${sessionId}/questions/${questionNumber}`
            );

            // Ensure newAnswer is an object
            await updateRealtime(sessionQuestionsRef, { correct_answer: newAnswer });

            // Optionally re-grade students' answers
            await regradeStudents(sessionId, questionNumber, newAnswer);

            alert('Correct answer updated and students re-graded.');

            // Refresh sessions and grades
            await fetchClassDetails();
        } catch (error) {
            console.error('Error updating correct answer:', error);
        } finally {
            setLoading(false);
        }

    };

    const regradeStudents = async (sessionId, questionNumber, newAnswer) => {
        try {
            // Fetch students' answers from Realtime Database
            const dbRealtime = getDatabase();
            const answersRef = refRealtime(
                dbRealtime,
                `answers/${classId}/${sessionId}/${questionNumber}`
            );
            const snapshot = await getRealtime(answersRef);
            if (snapshot.exists()) {
                const answersData = snapshot.val();
                for (const studentUid in answersData) {
                    const studentAnswer = answersData[studentUid].answer;
                    const isCorrect = studentAnswer === newAnswer;
                    // Update grades in Firestore with the correct field path
                    await updateDoc(doc(db, 'grades', classId), {
                        [`students.${studentUid}.${sessionId}.points.${questionNumber}`]: isCorrect ? 1 : 0,
                    });
                }
            }
        } catch (error) {
            console.error('Error regrading students:', error);
        }
    };
    

    if (loading) {
        return <Preloader />;
    }

    return (
        <>
            {/* Session Search */}
            <h3>Sessions</h3>
            <input
                type="text"
                placeholder="Search Sessions..."
                value={sessionSearchTerm}
                onChange={handleSessionSearch}
                className="form-control-sm me-2"
            />

            <div className="sessions-list">
                {filteredSessions.map((session) => (
                    <div key={session.id} className="session-item">
                        <div
                            className="session-header d-flex justify-content-between align-items-center"
                            onClick={() => toggleSessionExpand(session.id)}
                        >
                            <h4 className="mb-0">
                                Session on {new Date(session.start_date_time).toLocaleString()}
                            </h4>
                            <span>{expandedSessions[session.id] ? '▲' : '▼'}</span>
                        </div>

                        {expandedSessions[session.id] && (
                            <div className="session-details">
                                <p>Total Questions: {session.total_questions}</p>
                                {session.questions &&
                                    Object.entries(session.questions).map(([questionNumber, question]) => (
                                        <div key={questionNumber} className="question-item mb-4 p-3 border rounded">
                                        <h5 className="mb-3">Question {questionNumber}</h5>

                                        {/* Conditional Rendering for Question Photo */}
                                        {question.question_photo ? (
                                            question.question_photo === "InClass" ? (
                                            <div className="alert custom-alert" role="alert">
                                                This question was presented in the class.
                                            </div>
                                            ) : (
                                            <img
                                                src={question.question_photo}
                                                alt={`Question ${questionNumber}`}
                                                className="img-fluid mb-3"
                                            />
                                            )
                                        ) : null}

                                        <p className="mb-2">
                                            <strong>Type:</strong> {question.question_type}
                                        </p>

                                        {/* Possible Answers */}
                                        {question.possible_answers && (
                                            <div className="mb-2">
                                            <strong>Possible Answers:</strong>
                                            <ul className="list-group list-group-flush">
                                                {question.possible_answers.map((answer, index) => (
                                                <li key={index} className="list-group-item">
                                                    {answer}
                                                </li>
                                                ))}
                                            </ul>
                                            </div>
                                        )}

                                        <p className="mb-3">
                                            <strong>Correct Answer:</strong> {question.correct_answer || 'Not set'}
                                        </p>

                                        {/* Modify Correct Answer Button */}
                                        <button
                                            onClick={() => {
                                            const newAnswer = prompt(
                                                'Enter new correct answer:',
                                                question.correct_answer || ''
                                            );
                                            if (newAnswer !== null) {
                                                updateCorrectAnswer(session.id, questionNumber, newAnswer);
                                            }
                                            }}
                                            className="btn custom-btn"
                                        >
                                            Modify Correct Answer
                                        </button>
                                        </div>
                                    ))}

                            </div>
                        )}
                    </div>
                ))}
            </div>
        </>
    );
};

export default SessionList;
