// src/pages/Support.js
import React, { useEffect, useState, useRef } from 'react';
import { db, storage } from '../../firebase';
import {
  collection,
  onSnapshot,
  updateDoc,
  doc,
  query,
  orderBy,
  addDoc,
  serverTimestamp,
  deleteDoc,
  getDocs,
  getDoc,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject  } from 'firebase/storage';
import { FaPaperclip } from 'react-icons/fa'; // Import the attachment icon

function AdminSupport () {
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [messagesUnsubscribe, setMessagesUnsubscribe] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null); // For image attachment in chat
  const [modalImageUrl, setModalImageUrl] = useState(null); // For displaying full-size images

  // Ref for file input
  const chatAttachmentInputRef = useRef(null);

  useEffect(() => {
    const ticketsCollectionRef = collection(db, 'tickets');
    const q = query(ticketsCollectionRef, orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const ticketsData = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setTickets(ticketsData);
    });

    return () => unsubscribe();
  }, []);

  // Function to select a ticket and load its messages
  const selectTicket = (ticket) => {
    setSelectedTicket(ticket);

    const ticketDocRef = doc(db, 'tickets', ticket.id);
    const messagesCollectionRef = collection(ticketDocRef, 'messages');
    const q = query(messagesCollectionRef, orderBy('timestamp', 'asc'));

    // Unsubscribe from previous messages listener if any
    if (messagesUnsubscribe) {
      messagesUnsubscribe();
    }

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const msgs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setMessages(msgs);
    });

    setMessagesUnsubscribe(() => unsubscribe);
  };

  useEffect(() => {
    // Clean up the messages listener when component unmounts
    return () => {
      if (messagesUnsubscribe) {
        messagesUnsubscribe();
      }
    };
  }, [messagesUnsubscribe]);

  const sendMessage = async () => {
    if (newMessage.trim() === '' && !selectedImage) return;
    if (!selectedTicket) return;

    const messageData = {
      sender: 'admin',
      messageText: newMessage,
      timestamp: serverTimestamp(),
      userId: selectedTicket.userId, // Include userId for security rules
    };

    try {
      if (selectedImage) {
        // Upload the image to Firebase Storage
        const imageRef = ref(
          storage,
          `tickets/${selectedTicket.id}/messages/${Date.now()}_${selectedImage.name}`
        );
        const snapshot = await uploadBytes(imageRef, selectedImage);
        const downloadURL = await getDownloadURL(snapshot.ref);

        messageData.imageUrl = downloadURL;
        messageData.imageName = selectedImage.name;
      }

      const ticketDocRef = doc(db, 'tickets', selectedTicket.id);
      const messagesCollectionRef = collection(ticketDocRef, 'messages');
      await addDoc(messagesCollectionRef, messageData);

      setNewMessage(''); // Clear the input field
      setSelectedImage(null); // Clear the selected image
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleChatAttachmentClick = () => {
    chatAttachmentInputRef.current.click();
  };

  const handleChatAttachmentChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const deleteTicket = async () => {
    if (
      window.confirm(
        'Are you sure you want to delete this ticket? This action cannot be undone.'
      )
    ) {
      try {
        const ticketDocRef = doc(db, 'tickets', selectedTicket.id);
  
        // Delete attachments associated with the ticket
        const ticketDoc = await getDoc(ticketDocRef);
        const ticketData = ticketDoc.data();
  
        if (ticketData.attachment && ticketData.attachment.length > 0) {
          const deleteAttachmentPromises = ticketData.attachment.map(async (url) => {
            try {
              const fileRef = ref(storage, url);
              await deleteObject(fileRef);
            } catch (error) {
              console.error('Error deleting attachment from storage:', error);
            }
          });
          await Promise.all(deleteAttachmentPromises);
        }
  
        // Delete all messages in the subcollection and their attachments
        const messagesCollectionRef = collection(ticketDocRef, 'messages');
        const messagesSnapshot = await getDocs(messagesCollectionRef);
        const deleteMessagePromises = messagesSnapshot.docs.map(async (messageDoc) => {
          const messageData = messageDoc.data();
  
          // Delete any attachments in messages
          if (messageData.imageUrl) {
            try {
              const imageRef = ref(storage, messageData.imageUrl);
              await deleteObject(imageRef);
            } catch (error) {
              console.error('Error deleting message attachment from storage:', error);
            }
          }
  
          // Delete the message document
          await deleteDoc(messageDoc.ref);
        });
        await Promise.all(deleteMessagePromises);
  
        // Delete the ticket document
        await deleteDoc(ticketDocRef);
  
        // Close the modal
        setSelectedTicket(null);
  
        // Optionally, display a success message or refresh the tickets list
      } catch (error) {
        console.error('Error deleting ticket:', error);
        alert('An error occurred while deleting the ticket. Please try again.');
      }
    }
  };
  

  // Function to change ticket status
  const changeTicketStatus = async (status) => {
    try {
      const ticketDocRef = doc(db, 'tickets', selectedTicket.id);
      await updateDoc(ticketDocRef, {
        status,
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.error('Error updating ticket status:', error);
    }
  };

  // Functions to handle image modal
  const openImageModal = (imageUrl) => {
    setModalImageUrl(imageUrl);
  };

  const closeImageModal = () => {
    setModalImageUrl(null);
  };

  // Group tickets by status
  const ticketsByStatus = {
    New: [],
    'In Progress': [],
    Resolved: [],
    Closed: [],
  };

  tickets.forEach((ticket) => {
    const status = ticket.status || 'New';
    if (!ticketsByStatus[status]) {
      ticketsByStatus[status] = [];
    }
    ticketsByStatus[status].push(ticket);
  });

  return (
    <div className="container-fluid mt-4">
      <h1 className="mb-4">Support Ticket Dashboard</h1>
      <div className="row">
        {/* Columns for each status */}
        {Object.keys(ticketsByStatus).map((status) => (
          <div className="col-md-3" key={status}>
            <h4>{status}</h4>
            <ul className="list-group">
              {ticketsByStatus[status].map((ticket) => (
                <li
                  key={ticket.id}
                  className="list-group-item list-group-item-action"
                  onClick={() => selectTicket(ticket)}
                  style={{ cursor: 'pointer' }}
                >
                  <strong>{ticket.subject}</strong>
                  <br />
                  {ticket.name} - {ticket.priority}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {selectedTicket && (
        <div
          className="modal"
          style={{
            display: 'block',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-lg modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Ticket #{selectedTicket.ticketNumber}</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setSelectedTicket(null)}
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  <strong>Subject:</strong> {selectedTicket.subject}
                </p>
                <p>
                  <strong>Message:</strong> {selectedTicket.message}
                </p>
                {selectedTicket.attachment && selectedTicket.attachment.length > 0 && (
                  <div>
                    <h5>Attachments</h5>
                    <div className="d-flex flex-wrap">
                      {selectedTicket.attachment.map((url, index) => (
                        <div key={index} className="me-2 mb-2">
                          <img
                            src={url}
                            alt={`Attachment ${index + 1}`}
                            style={{ maxWidth: '100px', cursor: 'pointer' }}
                            onClick={() => openImageModal(url)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <p>
                  <strong>Name:</strong> {selectedTicket.name}
                </p>
                <p>
                  <strong>Email:</strong> {selectedTicket.email}
                </p>
                <p>
                  <strong>Priority:</strong> {selectedTicket.priority}
                </p>
                <p>
                  <strong>Status:</strong> {selectedTicket.status}
                </p>
                <hr />
                <h5>Messages</h5>
                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                  {messages.map((msg) => (
                    <div
                      key={msg.id}
                      className={`mb-2 ${msg.sender === 'user' ? '' : 'text-end'}`}
                    >
                      <div
                        className={`p-2 rounded ${
                          msg.sender === 'user' ? 'bg-light' : 'bg-light'
                        }`}
                      >
                        {msg.imageUrl && (
                          <div className="d-flex flex-wrap">
                            <img
                              src={msg.imageUrl}
                              alt={msg.imageName || 'Image'}
                              style={{ maxWidth: '100px', cursor: 'pointer' }}
                              onClick={() => openImageModal(msg.imageUrl)}
                            />
                          </div>
                        )}
                        {msg.messageText && <small>{msg.messageText}</small>}
                        <br />
                        <small className="text-muted">
                          {msg.timestamp &&
                            new Date(msg.timestamp.seconds * 1000).toLocaleString()}
                        </small>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="input-group mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type your message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                  />
                  {/* Attachment Icon */}
                  <span
                    className="input-group-text"
                    onClick={handleChatAttachmentClick}
                    style={{ cursor: 'pointer' }}
                  >
                    <FaPaperclip />
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleChatAttachmentChange}
                    ref={chatAttachmentInputRef}
                    style={{ display: 'none' }}
                  />
                  <button className="btn btn-primary" onClick={sendMessage}>
                    Send
                  </button>
                </div>
                {/* Display selected image name */}
                {selectedImage && (
                  <div className="mt-2">
                    Selected Image: {selectedImage.name}
                  </div>
                )}
              </div>
              <div className="modal-footer">
                <select
                  className="form-select w-auto"
                  value={selectedTicket.status}
                  onChange={(e) => changeTicketStatus(e.target.value)}
                >
                  <option value="New">New</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Resolved">Resolved</option>
                  <option value="Closed">Closed</option>
                </select>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={deleteTicket}
                >
                  Delete Ticket
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setSelectedTicket(null)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

{modalImageUrl && (
  <div
    className="modal show"
    style={{
      display: 'block',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'auto',
      zIndex: 1050, // Ensures the modal appears above other elements like the navbar
    }}
    onClick={closeImageModal}
    aria-modal="true"
    role="dialog"
  >
    <div
      className="modal-dialog modal-dialog-centered"
      style={{
        maxWidth: '90vw', // Limits the width to 90% of the viewport width
        maxHeight: '90vh', // Limits the height to 90% of the viewport height
        marginTop: '5vh', // Adds a top margin of 5% of the viewport height
        marginBottom: '5vh', // Adds a bottom margin of 5% of the viewport height
      }}
      onClick={(e) => e.stopPropagation()} // Prevents closing the modal when clicking inside the dialog
    >
      <div className="modal-content" style={{ height: '100%' }}>
        <div className="modal-body d-flex justify-content-center align-items-center" style={{ padding: '1rem', overflow: 'auto' }}>
          <img
            src={modalImageUrl}
            alt="Full-size"
            className="img-fluid"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain', // Maintains aspect ratio
            }}
          />
        </div>
        <div className="modal-footer" style={{ borderTop: '1px solid #dee2e6', padding: '0.5rem 1rem', textAlign: 'right' }}>
          <button className="btn btn-secondary" onClick={closeImageModal}>
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)}

    </div>
  );
}

export default AdminSupport;
