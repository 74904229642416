import React, { useState, useEffect } from 'react';
import Preloader from '../../components/Preloader';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { getDatabase, ref, get } from 'firebase/database';
import { db } from '../../firebase';
import { getAuth } from 'firebase/auth';
import { _toLeftRightCenter } from 'chart.js/helpers';

const ExportSection = () => {
    const [loading, setLoading] = useState(false);
    const [classes, setClasses] = useState([]);
    const [selectedClassId, setSelectedClassId] = useState('');

    useEffect(() => {
        const fetchClasses = async () => {
            setLoading(true);
            try {
                const auth = getAuth();
                const user = auth.currentUser;
                if (!user) {
                    alert('No user is logged in.');
                    setLoading(false);
                    return;
                }
                const q = query(
                    collection(db, 'classes'),
                    where('professor_uid', '==', user.uid)
                );
                const querySnapshot = await getDocs(q);
                const classesData = querySnapshot.docs.map(docSnap => ({
                    id: docSnap.id,
                    ...docSnap.data()
                }));
                setClasses(classesData);
            } catch (error) {
                console.error('Error fetching classes:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchClasses();
    }, []);

    const handleExport = async () => {
        if (!selectedClassId) {
            alert('Please select a class to export.');
            return;
        }
        setLoading(true);
        try {
            // Fetch enrollments
            const enrollmentsDoc = await getDoc(doc(db, 'enrollments', selectedClassId));
            if (!enrollmentsDoc.exists()) {
                alert('No enrollments found for this class');
                return;
            }
            const studentsUids = enrollmentsDoc.data().students || [];
            if (studentsUids.length === 0) {
                alert('No students enrolled in this class.');
                return;
            }

            // Fetch user data for students
            const userPromises = studentsUids.map(uid => getDoc(doc(db, 'users', uid)));
            const userDocs = await Promise.all(userPromises);
            const studentsData = userDocs.map(docSnap => {
                if (docSnap.exists()) {
                    return { uid: docSnap.id, ...docSnap.data() };
                } else {
                    return { uid: docSnap.id };
                }
            });

            // Fetch grades data
            const gradesDoc = await getDoc(doc(db, 'grades', selectedClassId));
            const gradesData = gradesDoc.exists() ? gradesDoc.data() : {};

            // Fetch sessions data from Realtime Database
            const rtdb = getDatabase();
            const sessionsRef = ref(rtdb, 'sessions/' + selectedClassId);
            const sessionsSnapshot = await get(sessionsRef);
            const sessionsData = sessionsSnapshot.exists() ? sessionsSnapshot.val() : {};

            // Compute total possible points
            const totalPossiblePoints = Object.values(sessionsData).reduce((sum, session) => {
                const numQuestions = session.total_questions || Object.keys(session.questions || {}).length;
                return sum + numQuestions;
            }, 0);

            // For each student, compute total points and percentage
            const csvData = studentsData.map(student => {
                const studentGrades = gradesData.students?.[student.uid] || {};
                const totalPoints = Object.values(studentGrades).reduce((sum, session) => {
                    const sessionPoints = Object.values(session.points || {}).reduce((s, p) => s + p, 0);
                    return sum + sessionPoints;
                }, 0);

                const percentage = totalPossiblePoints > 0 ? (totalPoints / totalPossiblePoints) * 100 : 0;

                return {
                    firstName: student.firstName || '',
                    lastName: student.lastName || '',
                    Points: totalPoints,
                    Total: totalPossiblePoints,
                    Percentage: percentage.toFixed(2)
                };
            });

            // Generate CSV content
            const headers = ['firstName', 'lastName', 'Points', 'Total', 'Percentage'];
            const csvRows = [];

            // Add headers
            csvRows.push(headers.join(','));

            // Add data rows
            csvData.forEach(row => {
                const values = headers.map(header => row[header]);
                csvRows.push(values.join(','));
            });

            // Create CSV blob
            const csvContent = csvRows.join('\n');
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

            // Create download link
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'grades_export.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(url);

            alert('Export successful!');
        } catch (error) {
            console.error('Error exporting data:', error);
            alert('Failed to export data.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <section id="export-section" className="justify-content-center align-items-center text-center min-vh-100 mb-4 content-section">
            {loading && <Preloader />}
            <h1>Export Section</h1>
            <p>Select a class to export grades:</p>
            <select
                value={selectedClassId}
                onChange={e => setSelectedClassId(e.target.value)}
                className="form-select mb-3 w-auto mx-auto"
            >
                <option value="">Select a class</option>
                {classes.map(cls => (
                    <option key={cls.id} value={cls.id}>
                        {cls.class_name}
                    </option>
                ))}
            </select>
            <button onClick={handleExport} className="btn custom-btn">
                Export Data
            </button>
        </section>
    );
};

export default ExportSection;
