import React from 'react';
import AppDownloadSection from '../components/AppDownloadSection';
import CostCalculator from '../components/CostCalculator';

function Home() {
  return (
    <div>
      <AppDownloadSection />
      <CostCalculator />
    </div>
  );
}

export default Home;
