// src/components/professor/SidebarDocumentation.js

import React from 'react';
import '../../css/professor/ProfessorDashboard.css'; // Ensure your CSS is properly imported
import PropTypes from 'prop-types';

function SidebarDocumentation({ activeSection }) {
  const navItems = [
    {
      href: '#installation-section',
      icon: 'bi bi-tools',
      label: 'Installation',
      id: 'installation-section',
    },
    {
      href: '#setup-section',
      icon: 'bi bi-file-earmark-check',
      label: 'Setup',
      id: 'setup-section',
    },
    {
      href: '#troubleshooting-section',
      icon: 'bi bi-exclamation-triangle',
      label: 'Troubleshooting',
      id: 'troubleshooting-section',
    },
    {
      href: '#exports-section',
      icon: 'bi bi-cloud-arrow-down',
      label: 'Data Exports',
      id: 'exports-section',
    },
    {
      href: '#tips-section',
      icon: 'bi bi-lightbulb',
      label: 'Tips and Tricks',
      id: 'tips-section',
    },
    {
      href: '#signup-signin-section',
      icon: 'bi bi-person-plus',
      label: 'Sign Up and Sign In',
      id: 'signup-signin-section',
    },
  ];

  return (
    <div className="sidebar">
      <ul className="sidebar-nav">
        {navItems.map((item) => (
          <li key={item.id}>
            <a
              href={item.href}
              className={`nav-link ${
                activeSection === item.id ? 'active' : ''
              }`}
            >
              <i className={item.icon}></i> {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

SidebarDocumentation.propTypes = {
  activeSection: PropTypes.string.isRequired,
};

export default SidebarDocumentation;
