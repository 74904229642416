// src/pages/professor/ProfessorDocumentation.js

import React, { useState, useEffect, useRef } from 'react';
import { auth } from '../../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import Navbar from '../../components/professor/NavBar';
import SidebarDocumentation from '../../components/professor/SidebarDocumentation';
import Preloader from '../../components/Preloader';

function ProfessorDocumentation() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const sectionRefs = useRef([]);
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        window.location.href = '/sign-in';
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const observerOptions = {
      root: null,
      threshold: 0.6,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    sectionRefs.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      if (observer) {
        sectionRefs.current.forEach((section) => {
          if (section) observer.unobserve(section);
        });
      }
    };
  }, [searchQuery]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      window.location.href = '/sign-in';
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const documentationSections = [
    {
      id: 'installation-section',
      title: 'Installation',
      text: `Installation
To get started with our application, follow these steps:
Clone the repository: git clone https://github.com/your-repo-url.git
Install dependencies: npm install
Configure Firebase: Set up your Firebase project and replace the configuration in firebase.js with your own.
Start the application: npm start
For a detailed guide, watch the installation video below:`,
      content: (
        <>
          <h2>Installation</h2>
          <p>To get started with our application, follow these steps:</p>
          <ol>
            <li>
              <strong>Clone the repository:</strong>
              <pre><code>git clone https://github.com/your-repo-url.git</code></pre>
            </li>
            <li>
              <strong>Install dependencies:</strong>
              <pre><code>npm install</code></pre>
            </li>
            <li>
              <strong>Configure Firebase:</strong>
              <p>Set up your Firebase project and replace the configuration in <code>firebase.js</code> with your own.</p>
            </li>
            <li>
              <strong>Start the application:</strong>
              <pre><code>npm start</code></pre>
            </li>
          </ol>
          <p>For a detailed guide, watch the installation video below:</p>
          <div className="ratio ratio-16x9">
            <iframe
              src="https://www.youtube.com/embed/your-video-id"
              allowFullScreen
              title="Installation Video"
            ></iframe>
          </div>
        </>
      ),
    },
    {
      id: 'setup-section',
      title: 'Setup',
      text: `Setup
After installing, set up the application as follows:
Create a Firebase project: Go to the Firebase console and create a new project.
Enable Authentication: In your Firebase project, enable Email/Password authentication.
Set up Firestore and Realtime Database: Create the necessary collections and nodes as per the database structure below.
Database Structure
Firestore users collection
users => uid => {
  first_name: string,
  last_name: string,
  role: string (professor/student),
  email: string
}

Firestore classes collection
classes => class_id => {
  professor_uid: string,
  class_name: string,
  class_invitation: string,
  start_date: date,
  end_date: date,
  statsaccess: true/false,
  gradeaccess: true,
  sessionaccess: true,
}

Firestore enrollments collection
enrollments => class_id => {
  Array of ( student_uid: string )
}

Firestore grades collection
grades => class_id => {
  student_uid => {
    session_id: string,
    points: Array of {
      question_number: int,
      point: int
    }
  }
}`,
      content: (
        <>
          <h2>Setup</h2>
          <p>After installing, set up the application as follows:</p>
          <ol>
            <li>
              <strong>Create a Firebase project:</strong>
              <p>Go to the Firebase console and create a new project.</p>
            </li>
            <li>
              <strong>Enable Authentication:</strong>
              <p>In your Firebase project, enable Email/Password authentication.</p>
            </li>
            <li>
              <strong>Set up Firestore and Realtime Database:</strong>
              <p>Create the necessary collections and nodes as per the database structure below.</p>
              <h3>Database Structure</h3>
              <img src="path/to/database-structure-image.png" alt="Database Structure" className="img-fluid mb-3" />
              <pre><code>{`// Firestore users collection
users => uid => {
  first_name: string,
  last_name: string,
  role: string (professor/student),
  email: string
}

// Firestore classes collection
classes => class_id => {
  professor_uid: string,
  class_name: string,
  class_invitation: string,
  start_date: date,
  end_date: date,
  statsaccess: true/false,
  gradeaccess: true,
  sessionaccess: true,
}

// Firestore enrollments collection
enrollments => class_id => {
  Array of ( student_uid: string )
}

// Firestore grades collection
grades => class_id => {
  student_uid => {
    session_id: string,
    points: Array of {
      question_number: int,
      point: int
    }
  }
}
`}</code></pre>
            </li>
          </ol>
        </>
      ),
    },
    {
      id: 'troubleshooting-section',
      title: 'Troubleshooting',
      text: `Troubleshooting
If you encounter issues, try the following steps:
Ensure all Firebase services are correctly set up.
Check your internet connection.
Refer to the error logs in the console for specific errors.
For more help, watch the troubleshooting video:`,
      content: (
        <>
          <h2>Troubleshooting</h2>
          <p>If you encounter issues, try the following steps:</p>
          <ul>
            <li>Ensure all Firebase services are correctly set up.</li>
            <li>Check your internet connection.</li>
            <li>Refer to the error logs in the console for specific errors.</li>
          </ul>
          <p>For more help, watch the troubleshooting video:</p>
          <div className="ratio ratio-16x9">
            <iframe
              src="https://www.youtube.com/embed/your-video-id"
              allowFullScreen
              title="Troubleshooting Video"
            ></iframe>
          </div>
        </>
      ),
    },
    {
      id: 'exports-section',
      title: 'Data Exports',
      text: `Data Exports
Professors can export data for analysis:
Export student grades.
Export session statistics.
Use the export feature in the dashboard to download CSV files.`,
      content: (
        <>
          <h2>Data Exports</h2>
          <p>Professors can export data for analysis:</p>
          <ul>
            <li>Export student grades.</li>
            <li>Export session statistics.</li>
          </ul>
          <p>Use the export feature in the dashboard to download CSV files.</p>
        </>
      ),
    },
    {
      id: 'tips-section',
      title: 'Tips and Tricks',
      text: `Tips and Tricks
Enhance your experience with these tips:
Use the session timer to manage class time efficiently.
Encourage student participation by using interactive questions.
Regularly backup your data.`,
      content: (
        <>
          <h2>Tips and Tricks</h2>
          <p>Enhance your experience with these tips:</p>
          <ul>
            <li>Use the session timer to manage class time efficiently.</li>
            <li>Encourage student participation by using interactive questions.</li>
            <li>Regularly backup your data.</li>
          </ul>
        </>
      ),
    },
    {
      id: 'signup-signin-section',
      title: 'Sign Up and Sign In',
      text: `Sign Up and Sign In
Users can sign up and sign in as follows:
Sign Up
New users can create an account by providing:
First Name
Last Name
Email
Password
Role (Professor/Student)
Sign In
Existing users can sign in using their email and password.`,
      content: (
        <>
          <h2>Sign Up and Sign In</h2>
          <p>Users can sign up and sign in as follows:</p>
          <h3>Sign Up</h3>
          <p>New users can create an account by providing:</p>
          <ul>
            <li>First Name</li>
            <li>Last Name</li>
            <li>Email</li>
            <li>Password</li>
            <li>Role (Professor/Student)</li>
          </ul>
          <h3>Sign In</h3>
          <p>Existing users can sign in using their email and password.</p>
          <img src="path/to/signup-signin-image.png" alt="Sign Up and Sign In" className="img-fluid" />
        </>
      ),
    },
  ];

  const filteredSections = documentationSections.filter((section) =>
    section.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    section.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return <Preloader />;
  }

  return (
    <div>
      <Navbar userEmail={user.email} onSignOut={handleSignOut} />
      <div className="dashboard-container section-padding" style={{marginTop: 80}}>
        <div className="row">
          <SidebarDocumentation activeSection={activeSection} />
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            <div className="pt-3">
              <input
                type="text"
                className="form-control mb-4"
                placeholder="Search documentation..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              {filteredSections.length > 0 ? (
                filteredSections.map((section, index) => (
                  <div
                    key={section.id}
                    id={section.id}
                    className="mb-5"
                    ref={(el) => (sectionRefs.current[index] = el)}
                  >
                    {section.content}
                  </div>
                ))
              ) : (
                <p>No documentation sections match your search.</p>
              )}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default ProfessorDocumentation;
