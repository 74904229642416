// src/pages/Student/StudentDashboard.js

import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from '../../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  updateDoc,
  arrayUnion,
  setDoc,
} from 'firebase/firestore';
import {
  ref as rtdbRef,
  onValue as rtdbOnValue,
  query as rtdbQuery,
  orderByChild as rtdbOrderByChild,
  equalTo as rtdbEqualTo,
  limitToFirst as rtdbLimitToFirst,
  set as rtdbSet,
  serverTimestamp as rtdbServerTimestamp,
  off as rtdbOff,
  get as rtdbGet,
} from 'firebase/database';
import { getDatabase } from 'firebase/database';
import Navbar from '../../components/student/NavBar';
import '../../css/student/StudentDashboard.css';
import Preloader from '../../components/Preloader';

function StudentDashboard() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [classes, setClasses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [invitationCode, setInvitationCode] = useState('');
  const [currentSession, setCurrentSession] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [localSelectedAnswer, setLocalSelectedAnswer] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);

  // Listener references
  const [questionListenerRef, setQuestionListenerRef] = useState(null);
  const [gradingListenerRef, setGradingListenerRef] = useState(null);
  const [pollStatusListenerRef, setPollStatusListenerRef] = useState(null);
  const [answerListenerRef, setAnswerListenerRef] = useState(null);
  const [sessionEndListenerRef, setSessionEndListenerRef] = useState(null);

  const database = getDatabase();
  const sessionListeners = useRef({});

  useEffect(() => {

    const currentSessionListeners = sessionListeners.current;
    // Check if user is signed in
    const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // Fetch the user document from Firestore
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();

          // Check if the role is 'student'
          if (userData.role === 'student') {
            setUser(currentUser);
            loadStudentClasses(currentUser); // Load student classes or any other actions
          } else {
            // Redirect if the role is not 'student'
            window.location.href = '/sign-in';
          }
        } else {
          console.log('No user document found.');
          window.location.href = '/sign-in';
        }
      } else {
        console.log('No user is signed in.');
        window.location.href = '/sign-in';
      }
    });

    // Cleanup on unmount
    return () => {
      unsubscribeAuth();

      if (questionListenerRef) {
        rtdbOff(questionListenerRef.ref, 'value', questionListenerRef.callback);
      }
      if (gradingListenerRef) {
        rtdbOff(gradingListenerRef.ref, 'value', gradingListenerRef.callback);
      }
      if (pollStatusListenerRef) {
        rtdbOff(pollStatusListenerRef.ref, 'value', pollStatusListenerRef.callback);
      }
      if (answerListenerRef) {
        rtdbOff(answerListenerRef.ref, 'value', answerListenerRef.callback);
      }
      if (sessionEndListenerRef) {
        rtdbOff(sessionEndListenerRef.ref, 'value', sessionEndListenerRef.callback);
      }

      Object.values(currentSessionListeners).forEach(({ ref, listener }) => {
        rtdbOff(ref, 'value', listener);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStudentClasses = async (currentUser) => {
    try {
      const classCards = [];
      const currentUserUid = currentUser.uid;

      // Query enrollments where students array contains currentUserUid
      const enrollmentsRef = collection(db, 'enrollments');
      const q = query(enrollmentsRef, where('students', 'array-contains', currentUserUid));
      const querySnapshot = await getDocs(q);

      for (const enrollmentDoc of querySnapshot.docs) {
        const classId = enrollmentDoc.id;
        const classDocRef = doc(db, 'classes', classId);
        const classDocSnap = await getDoc(classDocRef);

        if (classDocSnap.exists()) {
          const classData = classDocSnap.data();
          classCards.push({
            classId: classId,
            className: classData.class_name,
            classInvitation: classData.class_invitation,
          });
        }
      }

      classCards.forEach((classItem) => {
        listenForActiveSession(classItem.classId);
      });

      setClasses(classCards);
      setLoading(false);
    } catch (error) {
      console.error('Error loading student classes:', error);
      setLoading(false);
    }
  };

  const listenForActiveSession = (classId) => {
    const sessionsRef = rtdbRef(database, `sessions/${classId}`);

    const sessionQuery = rtdbQuery(
      sessionsRef,
      rtdbOrderByChild('end_date_time'),
      rtdbEqualTo(null)
    );

    const listener = rtdbOnValue(sessionQuery, (snapshot) => {
      setClasses((prevClasses) =>
        prevClasses.map((classItem) =>
          classItem.classId === classId
            ? { ...classItem, hasActiveSession: snapshot.exists() }
            : classItem
        )
      );
    });

    // Optionally, store the listener references if you need to detach them later
    sessionListeners.current[classId] = { ref: sessionQuery, listener };
  };

  const handleAddClassClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setInvitationCode('');
  };

  const handleInvitationSubmit = () => {
    if (invitationCode) {
      addStudentToClass(invitationCode);
    } else {
      alert('Please enter a valid invitation code.');
    }
  };

  const addStudentToClass = async (invitationCode) => {
    try {
      const classesRef = collection(db, 'classes');
      const q = query(classesRef, where('class_invitation', '==', invitationCode));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const classDoc = querySnapshot.docs[0];
        const classId = classDoc.id;
        const enrollmentDocRef = doc(db, 'enrollments', classId);
        const enrollmentDocSnap = await getDoc(enrollmentDocRef);

        if (enrollmentDocSnap.exists()) {
          const enrollmentData = enrollmentDocSnap.data();
          const studentsArray = enrollmentData.students || [];

          if (studentsArray.includes(user.uid)) {
            alert('You are already enrolled in this class.');
          } else {
            await updateDoc(enrollmentDocRef, {
              students: arrayUnion(user.uid),
            });
            // Initialize grades for the student in the grades collection
            const gradesDocRef = doc(db, 'grades', classId);
            await updateDoc(gradesDocRef, {
              [`students.${user.uid}`]: {},
            });

            alert('Successfully enrolled in the class!');
            handleModalClose();
            loadStudentClasses(user); // Refresh the class list
          }
        } else {
          // Enrollments document does not exist yet
          await setDoc(enrollmentDocRef, {
            students: [user.uid],
          });
          // Initialize grades for the student in the grades collection
          const gradesDocRef = doc(db, 'grades', classId);
          await setDoc(gradesDocRef, {
            students: {
              [user.uid]: {},
            },
          });
          alert('Successfully enrolled in the class!');
          handleModalClose();
          loadStudentClasses(user); // Refresh the class list
        }
      } else {
        alert('Invalid invitation code. Please try again.');
      }
    } catch (error) {
      console.error('Error adding student to class:', error);
    }
  };

  const handleClassClick = async (classId) => {
    try {
      // Reference sessions under the selected class
      const sessionsRef = rtdbRef(database, `sessions/${classId}`);

      // Query for a session with no end_date_time
      const sessionQuery = rtdbQuery(
        sessionsRef,
        rtdbOrderByChild('end_date_time'),
        rtdbEqualTo(null),
        rtdbLimitToFirst(1)
      );

      rtdbOnValue(
        sessionQuery,
        (snapshot) => {
          if (snapshot.exists()) {
            const sessionData = snapshot.val();
            const sessionId = Object.keys(sessionData)[0];
            // Hide the class card section and show the new section
            setCurrentSession({ classId, sessionId });
            setIsWaiting(true);
            listenForQuestions(classId, sessionId);
          } else {
            alert('No active session found or the session has already ended.');
          }
        },
        (error) => {
          console.error('Error fetching session:', error);
        },
        {
          onlyOnce: true,
        }
      );
    } catch (error) {
      console.error('Error fetching session:', error);
    }
  };

  const listenForQuestions = (classId, sessionId) => {
    // Remove previous question listener
    if (questionListenerRef) {
      rtdbOff(questionListenerRef.ref, 'value', questionListenerRef.callback);
    }
    if (gradingListenerRef) {
      rtdbOff(gradingListenerRef.ref, 'value', gradingListenerRef.callback);
    }
    if (pollStatusListenerRef) {
      rtdbOff(pollStatusListenerRef.ref, 'value', pollStatusListenerRef.callback);
    }
    if (answerListenerRef) {
      rtdbOff(answerListenerRef.ref, 'value', answerListenerRef.callback);
    }

    const questionsRef = rtdbRef(database, `sessions/${classId}/${sessionId}/questions`);

    const questionsCallback = (snapshot) => {
      if (snapshot.exists()) {
        const questionsData = snapshot.val();
        const questionNumbers = Object.keys(questionsData).map(Number);
        const lastQuestionNumber = Math.max(...questionNumbers);
        const currentQuestionData = questionsData[lastQuestionNumber];

        setCurrentQuestion({
          questionNumber: lastQuestionNumber,
          data: currentQuestionData,
        });

        // Reset the selected answer and grading status
        setLocalSelectedAnswer(null);
        setIsAnswerCorrect(null);

        setIsWaiting(false);
        listenForCorrectAnswer(classId, sessionId, lastQuestionNumber);

        // Listen for changes in the poll status
        const pollStatusRef = rtdbRef(
          database,
          `sessions/${classId}/${sessionId}/questions/${lastQuestionNumber}/pool`
        );

        // Remove previous poll status listener
        if (pollStatusListenerRef) {
          rtdbOff(pollStatusListenerRef.ref, 'value', pollStatusListenerRef.callback);
        }

        const pollStatusCallback = (pollSnapshot) => {
          const pollStatus = pollSnapshot.val();
          if (pollStatus === 'off') {
            // Poll is closed
            checkAndGradeIfNoAnswer(
              classId,
              sessionId,
              lastQuestionNumber,
              currentQuestionData.correct_answer
            );
          }
        };
        rtdbOnValue(pollStatusRef, pollStatusCallback);
        setPollStatusListenerRef({ ref: pollStatusRef, callback: pollStatusCallback });

        // Retrieve the answer submitted by the user for the current question
        const answerRef = rtdbRef(
          database,
          `answers/${classId}/${sessionId}/${lastQuestionNumber}/${user.uid}`
        );

        // Remove previous answer listener
        if (answerListenerRef) {
          rtdbOff(answerListenerRef.ref, 'value', answerListenerRef.callback);
        }

        const answerCallback = (answerSnapshot) => {
          if (answerSnapshot.exists()) {
            const answerData = answerSnapshot.val();
            setLocalSelectedAnswer(answerData.answer);
          } else {
            setLocalSelectedAnswer(null);
          }
        };

        rtdbOnValue(answerRef, answerCallback);
        setAnswerListenerRef({ ref: answerRef, callback: answerCallback });
      } else {
        setCurrentQuestion(null);
        setIsWaiting(true);
      }
    };

    rtdbOnValue(questionsRef, questionsCallback);
    setQuestionListenerRef({ ref: questionsRef, callback: questionsCallback });

    listenForSessionEnd(classId, sessionId);
  };

  const checkAndGradeIfNoAnswer = async (classId, sessionId, questionNumber, correctAnswer) => {
    try {
      // Check if student has already submitted an answer
      const answerRef = rtdbRef(
        database,
        `answers/${classId}/${sessionId}/${questionNumber}/${user.uid}`
      );
      const answerSnapshot = await rtdbGet(answerRef);
      if (!answerSnapshot.exists()) {
        // Student has not submitted an answer, grade as 0
        console.log('Student did not submit an answer, grading as 0.');

        // Optionally, you can set an empty answer in the database
        await rtdbSet(answerRef, {
          answer: null,
          timestamp: rtdbServerTimestamp(),
        });

        await gradeStudentAnswer(classId, sessionId, questionNumber, correctAnswer);
      }
    } catch (error) {
      console.error('Error checking for student answer:', error);
    }
  };

  const listenForCorrectAnswer = (classId, sessionId, questionNumber) => {
    // Remove previous grading listener
    if (gradingListenerRef) {
      rtdbOff(gradingListenerRef.ref, 'value', gradingListenerRef.callback);
    }

    const correctAnswerRef = rtdbRef(
      database,
      `sessions/${classId}/${sessionId}/questions/${questionNumber}/correct_answer`
    );

    const correctAnswerCallback = async (snapshot) => {
      if (snapshot.exists()) {
        const correctAnswer = snapshot.val();
        if (correctAnswer !== undefined) {
          // Reset grading status before grading the new answer
          setIsAnswerCorrect(null);

          // Set correct answer in state
          setCurrentQuestion((prev) => ({
            ...prev,
            data: {
              ...prev.data,
              correct_answer: correctAnswer,
            },
          }));

          // Proceed to grade
          await gradeStudentAnswer(classId, sessionId, questionNumber, correctAnswer);
        }
      }
    };

    rtdbOnValue(correctAnswerRef, correctAnswerCallback);
    setGradingListenerRef({ ref: correctAnswerRef, callback: correctAnswerCallback });
  };

  const gradeStudentAnswer = async (classId, sessionId, questionNumber, correctAnswer) => {
    try {
      // Fetch student's answer from Realtime Database
      const answerRef = rtdbRef(
        database,
        `answers/${classId}/${sessionId}/${questionNumber}/${user.uid}`
      );
      const answerSnapshot = await rtdbGet(answerRef);
      let studentAnswer = null;
      if (answerSnapshot.exists()) {
        studentAnswer = answerSnapshot.val().answer;
      }

      // Determine if the answer is correct
      const isCorrect = studentAnswer !== null && studentAnswer === correctAnswer;
      setIsAnswerCorrect(isCorrect);

      // Update grade in Firestore
      const gradesDocRef = doc(db, 'grades', classId);
      await updateDoc(gradesDocRef, {
        [`students.${user.uid}.${sessionId}.points.${questionNumber}`]: isCorrect ? 1 : 0,
      });

      console.log('Grade updated successfully');
    } catch (error) {
      console.error('Error grading student answer:', error);
    }
  };

  const listenForSessionEnd = (classId, sessionId) => {
    // Remove previous session end listener
    if (sessionEndListenerRef) {
      rtdbOff(sessionEndListenerRef.ref, 'value', sessionEndListenerRef.callback);
    }

    const sessionEndRef = rtdbRef(database, `sessions/${classId}/${sessionId}/end_date_time`);

    const sessionEndCallback = (snapshot) => {
      console.log('Session end listener triggered', snapshot.val());
      const endTime = snapshot.val();

      if (snapshot.exists() && endTime !== null) {
        console.log('Session ended at:', endTime);

        // Reset state variables to navigate back to the main dashboard view
        setCurrentSession(null);
        setCurrentQuestion(null);
        setLocalSelectedAnswer(null);
        setIsWaiting(false);
        setIsAnswerCorrect(null);

        // Optionally, you can show a message to the user
        alert('The session has ended.');
      } else {
        console.log('Session still active or end time not set yet.');
      }
    };

    rtdbOnValue(sessionEndRef, sessionEndCallback);
    setSessionEndListenerRef({ ref: sessionEndRef, callback: sessionEndCallback });
  };

  const submitAnswer = (answer) => {
    if (!currentSession || !currentQuestion) {
      return;
    }

    const { classId, sessionId } = currentSession;
    const { questionNumber } = currentQuestion;

    const currentUserUid = user.uid;
    const timestamp = rtdbServerTimestamp();

    const answerRef = rtdbRef(
      database,
      `answers/${classId}/${sessionId}/${questionNumber}/${currentUserUid}`
    );

    rtdbSet(answerRef, {
      answer: answer,
      timestamp: timestamp,
    })
      .then(() => {
        console.log('Answer submitted successfully!');
        setLocalSelectedAnswer(answer);
      })
      .catch((error) => {
        console.error('Error submitting answer:', error);
        alert('Failed to submit answer. Please try again.');
      });
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        window.location.href = '/sign-in';
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  if (loading) {
    return <Preloader />;
  }

  if (isWaiting) {
    return (
      <>
        <Navbar user={user} handleSignOut={handleSignOut} />
        <main>
          <section className="main-content section-padding">
            <div className="waiting-message">
              <h3>Waiting for professor to upload a question...</h3>
            </div>
          </section>
        </main>
      </>
    );
  }

  if (currentSession && currentQuestion) {
    const questionData = currentQuestion.data;
    const pollActive = questionData.pool === 'on';
    const questionType = questionData.question_type;
    const correctAnswer = questionData.correct_answer;

    let answerInputHtml = null;

    if (questionType === 'multiple_choice') {
      answerInputHtml = (
        <div className="multiple-choice">
          <p>Choose your answer:</p>
          {['A', 'B', 'C', 'D'].map((option) => (
            <button
              key={option}
              className={`answer-btn ${localSelectedAnswer === option ? 'selected' : ''}`}
              disabled={!pollActive}
              onClick={() => submitAnswer(option)}
            >
              {option}
            </button>
          ))}
        </div>
      );
    } else if (questionType === 'yes_no') {
      answerInputHtml = (
        <div className="yes-no">
          <p>Choose your answer:</p>
          {['Yes', 'No'].map((option) => (
            <button
              key={option}
              className={`answer-btn ${localSelectedAnswer === option ? 'selected' : ''}`}
              disabled={!pollActive}
              onClick={() => submitAnswer(option)}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </button>
          ))}
        </div>
      );
    } else if (questionType === 'short_input') {
      answerInputHtml = (
        <div className="short-input">
          <p>Type your answer:</p>
          <input
            type="text"
            id="short-answer"
            value={localSelectedAnswer || ''}
            placeholder="Enter your answer"
            onChange={(e) => setLocalSelectedAnswer(e.target.value)}
            disabled={!pollActive}
          />
          <button
            className="answer-btn"
            onClick={() => submitAnswer(localSelectedAnswer)}
            disabled={!pollActive}
          >
            Submit
          </button>
        </div>
      );
    }

    return (
      <>
        <Navbar user={user} handleSignOut={handleSignOut} />
        <main>
          <section className="main-content section-padding" key={currentQuestion.questionNumber}>
            <div className="session-details">
              <h3>Question {currentQuestion.questionNumber}</h3>

              {questionData.question_photo === 'InClass' ? (
                <h2 className="inclass-message">
                  This question has been posted in class.
                </h2>
              ) : (
                <img
                  src={questionData.question_photo}
                  alt="Question"
                  className="responsive-image"
                />
              )}

              {answerInputHtml}

              {correctAnswer !== undefined && (
                <div className="correct-answer" style={{ marginTop: '20px' }}>
                  <h4 style={{ color: 'green' }}>Correct Answer: {correctAnswer}</h4>
                  <h4
                    style={{
                      color:
                        isAnswerCorrect === null
                          ? 'black' // Answer not graded yet
                          : isAnswerCorrect
                          ? 'green'
                          : 'red',
                    }}
                  >
                    Your Answer: {localSelectedAnswer || 'Not answered'}
                  </h4>
                </div>
              )}

              {!pollActive && (
                <p className="poll-closed-msg">
                  Poll is closed. You cannot change your answer.
                </p>
              )}
            </div>
          </section>
        </main>
      </>
    );
  }

  return (
    <>
      <Navbar user={user} handleSignOut={handleSignOut} />
      <main>
        <section className="main-content">
          <div id="class-cards" className="container">
            {classes.map((classItem) => (
              <div
                key={classItem.classId}
                className={`class-card ${
                  classItem.hasActiveSession ? 'active-session' : ''
                }`}
                onClick={() => handleClassClick(classItem.classId)}
              >
                <h3>{classItem.className}</h3>
                {classItem.hasActiveSession && (
                  <p className="session-active">Session is active</p>
                )}
                <p>Invitation Code: {classItem.classInvitation}</p>
              </div>
            ))}
          </div>
          <button
            id="add-class-btn"
            className="btn custom-btn mt-4"
            onClick={handleAddClassClick}
          >
            Add Class
          </button>
        </section>
      </main>
      {/* Modal */}
      {showModal && (
        <div id="class-form-modal" className="modal" style={{ display: 'block' }}>
          <div className="modal-content">
            <span
              className="close-btn"
              style={{ cursor: 'pointer' }}
              onClick={handleModalClose}
            >
              &times;
            </span>
            <h4>Enter Invitation Code</h4>
            <input
              type="text"
              id="invitation-code"
              className="form-control"
              placeholder="Invitation Code"
              required
              value={invitationCode}
              onChange={(e) => setInvitationCode(e.target.value)}
            />
            <button
              id="submit-invitation"
              className="btn custom-btn mt-3"
              onClick={handleInvitationSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default StudentDashboard;
