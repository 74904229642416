// src/pages/NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <>
      <div className="container mt-5 pt-5">
        <div className="text-center">
          <h1>404 - Page Not Found</h1>
          <p>
            Oops! The page you're looking for does not exist.
          </p>
          <p>
            Please contact us at <a href="mailto:support@coursengage.com">support@coursengage.com</a> for assistance.
          </p>
        </div>
      </div>
    </>
  );
};

export default NotFound;
