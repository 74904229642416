import React, { useState } from 'react';
import Preloader from '../../components/Preloader';
//import './InstallSection.css'; // Create this CSS file for styling

const InstallSection = () => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async (platform) => {
    setLoading(true);
    let downloadLink = '';

    if (platform === 'Windows') {
      // Direct download link for Windows
      downloadLink = 'https://coursengage.netlify.app/CoursEngage%20Setup%201.0.0.exe';
    } else if (platform === 'Mac') {
      // Direct download link for Mac
      downloadLink = 'https://coursengage.netlify.app/CoursEngage%20Setup%201.0.0.dmg';
    }

    try {
      // Implement the download functionality here
      window.location.href = downloadLink; // Trigger the download
      await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate async operation
      alert(`Download for ${platform} started!`);
    } catch (error) {
      console.error(`Error downloading for ${platform}:`, error);
      alert(`Failed to download for ${platform}.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="install-section" className="justify-content-center align-items-center text-center min-vh-100 mb-4 content-section">
      {loading && <Preloader />}
      <h1>Install Section</h1>
      <p>Download the application for your operating system:</p>
      <div className="install-buttons">
        <button onClick={() => handleDownload('Windows')} className="btn custom-btn">
          <i className="bi bi-windows"></i> Download for Windows
        </button>
        <button onClick={() => handleDownload('Mac')} className="btn custom-btn">
          <i className="bi bi-apple"></i> Download for Mac
        </button>
      </div>
    </section>
  );
};

export default InstallSection;