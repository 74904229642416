// src/components/student/StudentStats.js

import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import Preloader from '../Preloader';

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const StudentStats = ({ grades, sessions }) => {
  const [loading, setLoading] = useState(true);
  const [meanGrade, setMeanGrade] = useState(0);
  const [attendanceData, setAttendanceData] = useState({});
  const [gradeDistributionData, setGradeDistributionData] = useState({});

  useEffect(() => {
    const calculateStats = () => {
      setLoading(true);
      try {
        // Total possible points
        const totalPossiblePoints = sessions.reduce((sum, session) => {
          const numQuestions =
            session.total_questions ||
            Object.keys(session.questions || {}).length;
          return sum + numQuestions;
        }, 0);

        // Student's total points
        const studentGradeData = grades || {};
        const totalPoints = Object.values(studentGradeData).reduce(
          (sum, session) => {
            const sessionPoints = Object.values(session.points || {}).reduce(
              (s, p) => s + p,
              0
            );
            return sum + sessionPoints;
          },
          0
        );

        const percentage =
          totalPossiblePoints > 0
            ? (totalPoints / totalPossiblePoints) * 100
            : 0;

        setMeanGrade(percentage.toFixed(2));

        // Attendance calculation
        const totalSessions = sessions.length;
        let attendedSessions = Object.keys(grades || {}).length;

        // Prepare data for attendance chart
        setAttendanceData({
          labels: ['Attended', 'Missed'],
          datasets: [
            {
              data: [attendedSessions, totalSessions - attendedSessions],
              backgroundColor: ['rgba(75,192,192,0.6)', 'rgba(255,99,132,0.6)'],
            },
          ],
        });

        // Prepare data for grade distribution (per session)
        const sessionLabels = sessions.map((session) =>
          new Date(session.start_date_time).toLocaleDateString()
        );
        const sessionGrades = sessions.map((session) => {
          const sessionId = session.id;
          const sessionData = grades[sessionId] || {};
          const sessionPoints = Object.values(sessionData.points || {}).reduce(
            (sum, p) => sum + p,
            0
          );
          const sessionTotalQuestions =
            session.total_questions ||
            Object.keys(session.questions || {}).length;
          return sessionTotalQuestions > 0
            ? ((sessionPoints / sessionTotalQuestions) * 100).toFixed(2)
            : 0;
        });

        setGradeDistributionData({
          labels: sessionLabels,
          datasets: [
            {
              label: 'Grade (%)',
              data: sessionGrades,
              backgroundColor: 'rgba(153,102,255,0.6)',
            },
          ],
        });
      } catch (error) {
        console.error('Error calculating stats:', error);
      } finally {
        setLoading(false);
      }
    };

    calculateStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grades, sessions]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <div className="stats-section">
      <h3>Your Statistics</h3>
      <div className="row">
        <div className="col-md-6">
          <div className="stat-card card text-center">
            <div className="card-body">
              <h5 className="card-title">Overall Grade</h5>
              <p className="card-text display-4">{meanGrade}%</p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h4 className="mt-4">Attendance</h4>
          <div className="chart-container mb-4" style={{ height: '300px' }}>
            <Pie
              data={attendanceData}
              options={{ responsive: true, maintainAspectRatio: false }}
            />
          </div>
        </div>
      </div>
      <h4 className="mt-4">Grades per Session</h4>
      <div className="chart-container mb-4" style={{ height: '300px' }}>
        <Bar
          data={gradeDistributionData}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      </div>
    </div>
  );
};

export default StudentStats;
