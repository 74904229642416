import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

// Create AuthContext
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => useContext(AuthContext);

// AuthProvider component to wrap around your app
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({
    isAuthenticated: false,
    role: null,
    uid: null,
  });
  const [loading, setLoading] = useState(true); // Loading state to handle initial auth check

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const docSnapshot = await getDoc(userDocRef);
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            setUser({
              isAuthenticated: true,
              role: userData.role,
              uid: currentUser.uid,
            });
          } else {
            // If user data not found, sign out
            await auth.signOut();
            setUser({
              isAuthenticated: false,
              role: null,
              uid: null,
            });
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUser({
            isAuthenticated: false,
            role: null,
            uid: null,
          });
        }
      } else {
        setUser({
          isAuthenticated: false,
          role: null,
          uid: null,
        });
      }
      setLoading(false); // Finished checking auth state
    });

    return () => unsubscribe();
  }, []);

  // No longer needed since AuthContext manages auth state
  // const login = (role) => { ... };
  // const logout = () => { ... };

  return (
    <AuthContext.Provider value={{ user, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
