import React, { useState, useEffect } from 'react';
import { auth, db } from '../../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import Navbar from '../../components/professor/NavBar';
import Sidebar from '../../components/professor/Sidebar';
import ClassSection from '../../components/professor/ClassSection';
import ExportSection from '../../components/professor/ExportSection';
import InstallSection from '../../components/professor/InstallSection';
import SettingsSection from '../../components/professor/SettingsSection';
import Preloader from '../../components/Preloader';
import '../../css/professor/ProfessorDashboard.css';
import { doc, getDoc } from "firebase/firestore";

function ProfessorDashboard() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSidebarVisible] = useState(true); // State to manage sidebar visibility

  useEffect(() => {
    // Check if user is signed in
    const unsubscribeAuth = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // Fetch the user document from Firestore
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
  
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
  
          // Check if the role is 'professor'
          if (userData.role === 'professor') {
            setUser(currentUser);
          } else {
            // Redirect if the role is not 'professor'
            window.location.href = '/sign-in';
          }
        } else {
          console.log('No user document found.');
          window.location.href = '/sign-in';
        }
      } else {
        console.log('No user is signed in.');
        window.location.href = '/sign-in';
      }
      setLoading(false);
    });
  
    // Cleanup on unmount
    return () => unsubscribeAuth();
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      window.location.href = '/sign-in';
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (loading) {
    return <Preloader />;
  }

  return (
    <>
      <Navbar userEmail={user.email} onSignOut={handleSignOut} />
      <div className={isSidebarVisible ? 'sidebar-active' : ''}>
        <Sidebar />
        <div className="dashboard-container">
          <main>
            <ClassSection />
            <ExportSection />
            {/* <UsageSection /> */}
            <InstallSection />
            <SettingsSection />
          </main>
        </div>
      </div>
    </>
  );
}

export default ProfessorDashboard;
