import React from 'react';

const ProductDetail = () => {
  return (
    <>
      <main>
        {/* Hero Section */}
        <section className="product-detail section-padding">
          <div className="container">
            <div className="row align-items-center">
              {/* Video Section */}
              <div className="col-lg-6 col-12">
                <div class="ratio ratio-16x9">
                  <iframe src="https://www.youtube.com/embed/pDkj8Z2P0Bw?si=leo8olzduRMmO9qW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>

              {/* Information Section */}
              <div className="col-lg-6 col-12">
                <div className="product-info d-flex flex-column">
                  <div>
                    <h2 className="product-title mb-3">CoursEngage</h2>
                    <p className="product-p">Transforming Classroom Interactions</p>
                  </div>
                  <small className="product-price text-muted mt-auto mb-4">Free to Start</small>

                  {/* Description */}
                  <div className="product-description">
                    <strong className="d-block mt-4 mb-2">About CoursEngage</strong>
                    <p className="mb-4">
                      CoursEngage revolutionizes the classroom experience by enabling real-time polling and interactive sessions. Professors can effortlessly create and manage classes, while students engage actively through instant feedback.
                    </p>
                  </div>

                  {/* Call to Action */}
                  <div className="product-cart-thumb row">
                    <div className="col-lg-6 col-12">
                      <a href="/signup" className="btn custom-btn cart-btn w-100">
                        Get Started
                      </a>
                    </div>
                    {/* <div className="col-lg-6 col-12 mt-3 mt-lg-0">
                      <a href="/demo" className="btn custom-btn cart-btn w-100">
                        Watch Demo
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="features section-padding border-top">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-5">
                <h3>Key Features</h3>
                <p className="text-muted">
                  Designed to enhance engagement and streamline classroom interactions.
                </p>
              </div>

              {/* Feature 1 */}
              <div className="col-lg-4 col-12 mb-4">
                <div className="feature-thumb text-center p-4">
                  <h5>User Registration & Roles</h5>
                  <p>Easy signup process with role-based access for professors and students.</p>
                </div>
              </div>

              {/* Feature 2 */}
              <div className="col-lg-4 col-12 mb-4">
                <div className="feature-thumb text-center p-4">
                  <h5>Class Creation & Enrollment</h5>
                  <p>Professors create classes with unique codes, making enrollment seamless and secure.</p>
                </div>
              </div>

              {/* Feature 3 */}
              <div className="col-lg-4 col-12 mb-4">
                <div className="feature-thumb text-center p-4">
                  <h5>Real-Time Polling</h5>
                  <p>Engage students with live polls and instant feedback during sessions.</p>
                </div>
              </div>

              {/* Feature 4 */}
              <div className="col-lg-4 col-12 mb-4">
                <div className="feature-thumb text-center p-4">
                  <h5>Session Management</h5>
                  <p>Professors control polling sessions, ensuring organized and time-bound interactions.</p>
                </div>
              </div>

              {/* Feature 5 */}
              <div className="col-lg-4 col-12 mb-4">
                <div className="feature-thumb text-center p-4">
                  <h5>Grading & Points</h5>
                  <p>Automatically assign points based on student responses to track performance.</p>
                </div>
              </div>

              {/* Feature 6 */}
              <div className="col-lg-4 col-12 mb-4">
                <div className="feature-thumb text-center p-4">
                  <h5>Secure & Reliable</h5>
                  <p>Built on Firebase for secure data handling and real-time reliability.</p>
                </div>
              </div>

            </div>
          </div>
        </section>
        <section className="structure-design section-padding border-top">
          <div className="container">
            <div className="row">
              {/* Section Header */}
              <div className="col-12 text-center mb-5">
                <h3>Structure Design</h3>
                <p className="text-muted">
                  Overview of the system architecture and database design.
                </p>
              </div>

              {/* Centered Image */}
              <div className="col-12 d-flex justify-content-center">
                <img
                  src="images/product/database_diagram.png"
                  className="img-fluid mb-3"
                  alt="Database Diagram"
                  style={{ maxHeight: '400px', objectFit: 'contain' }}
                />
              </div>
            </div>
          </div>
        </section>


        {/* Testimonials Section */}
        <section className="testimonials section-padding bg-light">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-5">
                <h3>What Our (Future) Users Say</h3>
                <p className="text-muted">Join the early adopters who are about to transform their classrooms.</p>
              </div>

              {/* Placeholder Testimonial 1 */}
              <div className="col-lg-6 col-12 mb-4">
                <div className="testimonial-thumb p-4">
                  <p className="testimonial-text">
                    "I haven't used CoursEngage yet, but I hear it's going to make my lectures 100% more interactive. Fingers crossed!"
                  </p>
                  <h5 className="testimonial-author">Dr. Anticipation</h5>
                  <small className="text-muted">Professor of Optimism</small>
                </div>
              </div>

              {/* Placeholder Testimonial 2 */}
              <div className="col-lg-6 col-12 mb-4">
                <div className="testimonial-thumb p-4">
                  <p className="testimonial-text">
                    "Still in beta, but if CoursEngage is half as cool as they say, my study sessions are about to get a major upgrade."
                  </p>
                  <h5 className="testimonial-author">Jane Future-Student</h5>
                  <small className="text-muted">Eager Learner</small>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/* Call to Action Section */}
        <section className="cta section-padding">
          <div className="container text-center">
            <h3>Ready to Transform Your Classroom?</h3>
            <p className="text-muted mb-4">
              Join thousands of educators and students who are enhancing their learning experience with CoursEngage.
            </p>
            <a href="/sign-up" className="btn custom-btn">
              Get Started Now
            </a>
          </div>
        </section>
      </main>
    </>
  );
};

export default ProductDetail;
