// src/components/professor/ClassFormModal.js
import React, { useState } from 'react';

const ClassFormModal = ({ closeModal, handleAddClass }) => {
  const [newClass, setNewClass] = useState({
    class_name: '',
    start_date: '',
    end_date: '',
  });

  const handleInputChange = (e) => {
    setNewClass({
      ...newClass,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { start_date, end_date } = newClass;

    if (new Date(start_date) > new Date(end_date)) {
      alert('End Date must be after Start Date.');
      return;
    }

    handleAddClass(newClass);
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-btn" onClick={closeModal}>
          &times;
        </span>
        <h2>Register New Class</h2>
        <form onSubmit={onSubmit}>
          <label htmlFor="class_name">Class Name:</label>
          <input
            type="text"
            id="class_name"
            name="class_name"
            value={newClass.class_name}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="start_date">Start Date:</label>
          <input
            type="date"
            id="start_date"
            name="start_date"
            value={newClass.start_date}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="end_date">End Date:</label>
          <input
            type="date"
            id="end_date"
            name="end_date"
            value={newClass.end_date}
            onChange={handleInputChange}
            required
          />
          <button type="submit" className="btn custom-btn">
            Create Class
          </button>
        </form>
      </div>
    </div>
  );
};

export default ClassFormModal;
