// src/components/professor/Stats.js
import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import Preloader from '../../Preloader';

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const Stats = ({ students, sessions, grades, classId }) => {
  const [loading, setLoading] = useState(true);
  const [meanGrade, setMeanGrade] = useState(0);
  const [lowestGrade, setLowestGrade] = useState(0);
  const [highestGrade, setHighestGrade] = useState(0);
  const [attendanceData, setAttendanceData] = useState({});
  const [participationData, setParticipationData] = useState({});
  const [gradeDistributionData, setGradeDistributionData] = useState({});

  useEffect(() => {
    const calculateStats = () => {
      setLoading(true);
      try {
        // Total possible points
        const totalPossiblePoints = sessions.reduce((sum, session) => {
          const numQuestions =
            session.total_questions || Object.keys(session.questions || {}).length;
          return sum + numQuestions;
        }, 0);

        // Grades for each student
        const studentGrades = students.map((student) => {
          const studentGradeData = grades.students?.[student.uid] || {};
          const totalPoints = Object.values(studentGradeData).reduce(
            (sum, session) => {
              const sessionPoints = Object.values(session.points || {}).reduce(
                (s, p) => s + p,
                0
              );
              return sum + sessionPoints;
            },
            0
          );
          const percentage =
            totalPossiblePoints > 0
              ? (totalPoints / totalPossiblePoints) * 100
              : 0;
          return {
            uid: student.uid,
            name: `${student.firstName} ${student.lastName}`,
            totalPoints,
            percentage,
          };
        });

        // Mean, lowest, highest grades
        const percentages = studentGrades.map((s) => s.percentage);
        const mean =
          percentages.reduce((sum, p) => sum + p, 0) /
          (percentages.length || 1);
        const lowest = Math.min(...percentages);
        const highest = Math.max(...percentages);

        setMeanGrade(mean.toFixed(2));
        setLowestGrade(lowest.toFixed(2));
        setHighestGrade(highest.toFixed(2));

        // Attendance calculation
        const totalSessions = sessions.length;
        const attendanceCounts = students.map((student) => {
          let attendedSessions = 0;
          sessions.forEach((session) => {
            if (grades.students?.[student.uid]?.[session.id]) {
              attendedSessions += 1;
            }
          });
          return {
            uid: student.uid,
            name: `${student.firstName} ${student.lastName}`,
            attendedSessions,
          };
        });

        // Prepare data for attendance chart
        const attendanceLabels = attendanceCounts.map((s) => s.name);
        const attendanceDataPoints = attendanceCounts.map(
          (s) => s.attendedSessions
        );

        setAttendanceData({
          labels: attendanceLabels,
          datasets: [
            {
              label: 'Sessions Attended',
              data: attendanceDataPoints,
              backgroundColor: 'rgba(75,192,192,0.6)',
            },
          ],
        });

        // Participation calculation (percentage of total points)
        const participationLabels = studentGrades.map((s) => s.name);
        const participationDataPoints = studentGrades.map((s) =>
          s.percentage.toFixed(2)
        );

        setParticipationData({
          labels: participationLabels,
          datasets: [
            {
              label: 'Participation (%)',
              data: participationDataPoints,
              backgroundColor: 'rgba(153,102,255,0.6)',
            },
          ],
        });

        // Grade distribution
        const gradeDistribution = {
          'A (90-100%)': 0,
          'B (80-89%)': 0,
          'C (70-79%)': 0,
          'D (60-69%)': 0,
          'F (<60%)': 0,
        };

        percentages.forEach((percentage) => {
          if (percentage >= 90) {
            gradeDistribution['A (90-100%)'] += 1;
          } else if (percentage >= 80) {
            gradeDistribution['B (80-89%)'] += 1;
          } else if (percentage >= 70) {
            gradeDistribution['C (70-79%)'] += 1;
          } else if (percentage >= 60) {
            gradeDistribution['D (60-69%)'] += 1;
          } else {
            gradeDistribution['F (<60%)'] += 1;
          }
        });

        // Prepare data for grade distribution chart
        const gradeLabels = Object.keys(gradeDistribution);
        const gradeDataPoints = Object.values(gradeDistribution);

        setGradeDistributionData({
          labels: gradeLabels,
          datasets: [
            {
              data: gradeDataPoints,
              backgroundColor: [
                '#4caf50',
                '#2196f3',
                '#ffeb3b',
                '#ff9800',
                '#f44336',
              ],
            },
          ],
        });
      } catch (error) {
        console.error('Error calculating stats:', error);
      } finally {
        setLoading(false);
      }
    };

    calculateStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students, sessions, grades]);

  if (loading) {
    return <Preloader />;
  }

  return (
    <div className="stats-section">
      <h3>Class Statistics</h3>
      <div className="row">
        <div className="col-md-4">
          <div className="stat-card card text-center">
            <div className="card-body">
              <h5 className="card-title">Mean Grade</h5>
              <p className="card-text display-4">{meanGrade}%</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="stat-card card text-center">
            <div className="card-body">
              <h5 className="card-title">Highest Grade</h5>
              <p className="card-text display-4">{highestGrade}%</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="stat-card card text-center">
            <div className="card-body">
              <h5 className="card-title">Lowest Grade</h5>
              <p className="card-text display-4">{lowestGrade}%</p>
            </div>
          </div>
        </div>
      </div>
      <h4 className="mt-4">Attendance</h4>
      <div className="chart-container mb-4" style={{ height: '300px' }}>
        <Bar
          data={attendanceData}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      </div>
      <h4 className="mt-4">Participation</h4>
      <div className="chart-container mb-4" style={{ height: '300px' }}>
        <Bar
          data={participationData}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      </div>
      <h4 className="mt-4">Grade Distribution</h4>
      <div className="chart-container mb-4" style={{ height: '300px' }}>
        <Pie
          data={gradeDistributionData}
          options={{ responsive: true, maintainAspectRatio: false }}
        />
      </div>
    </div>
  );
};

export default Stats;
