// // src/pages/SignUp.js

// import React, { useState } from 'react';
// import { auth, db } from '../firebase';
// import { createUserWithEmailAndPassword } from 'firebase/auth';
// import { doc, setDoc } from 'firebase/firestore';

// function SignUp() {
//   const [firstName, setFirstName] = useState('');
//   const [lastName, setLastName] = useState('');
//   const [role, setRole] = useState('');
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (password !== confirmPassword) {
//       setErrorMessage("Passwords don't match");
//       return;
//     }

//     try {
//       // Create user with email and password
//       const userCredential = await createUserWithEmailAndPassword(
//         auth,
//         email,
//         password
//       );
//       const user = userCredential.user;

//       // Store user details in Firestore
//       await setDoc(doc(db, 'users', user.uid), {
//         firstName,
//         lastName,
//         role,
//         email,
//       });

//       alert('User registered and data stored!');
//       setFirstName('');
//       setLastName('');
//       setRole('');
//       setEmail('');
//       setPassword('');
//       setConfirmPassword('');
//       setErrorMessage('');
//       window.location.href = '/sign-in'; // Redirect to sign-in page
//     } catch (error) {
//       setErrorMessage(error.message.replace('firebase', 'CoursEngage'));
//     }
//   };

//   return (
//     <>
//       <section className="sign-in-form section-padding">
//         <div className="container">
//           <div className="row">
//             <div className="col-lg-8 mx-auto col-12">
//               <h1 className="hero-title text-center mb-5">Sign Up</h1>
//               <div className="row">
//                 <div className="col-lg-8 col-11 mx-auto">
//                   <form id="signupForm" onSubmit={handleSubmit}>
//                     <div className="form-floating my-4">
//                       <input
//                         type="text"
//                         id="first_name"
//                         className="form-control"
//                         placeholder="First Name"
//                         value={firstName}
//                         onChange={(e) => setFirstName(e.target.value)}
//                         required
//                       />
//                       <label htmlFor="first_name">First Name</label>
//                     </div>

//                     <div className="form-floating my-4">
//                       <input
//                         type="text"
//                         id="last_name"
//                         className="form-control"
//                         placeholder="Last Name"
//                         value={lastName}
//                         onChange={(e) => setLastName(e.target.value)}
//                         required
//                       />
//                       <label htmlFor="last_name">Last Name</label>
//                     </div>

//                     <div className="form-floating my-4">
//                       <select
//                         className="form-select"
//                         id="role"
//                         value={role}
//                         onChange={(e) => setRole(e.target.value)}
//                         required
//                       >
//                         <option value="" disabled>
//                           Select Role
//                         </option>
//                         <option value="student">Student</option>
//                         <option value="professor">Professor</option>
//                       </select>
//                       <label htmlFor="role">You are a...</label>
//                     </div>

//                     <div className="form-floating my-4">
//                       <input
//                         type="email"
//                         id="email"
//                         className="form-control"
//                         placeholder="Email address"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                       />
//                       <label htmlFor="email">Email address</label>
//                     </div>

//                     <div className="form-floating my-4">
//                       <input
//                         type="password"
//                         id="password"
//                         className="form-control"
//                         placeholder="Password"
//                         value={password}
//                         onChange={(e) => setPassword(e.target.value)}
//                         required
//                       />
//                       <label htmlFor="password">Password</label>
//                       <p className="text-center">
//                         * shall include 0-9 a-z A-Z in 4 to 10 characters
//                       </p>
//                     </div>

//                     <div className="form-floating">
//                       <input
//                         type="password"
//                         id="confirm_password"
//                         className="form-control"
//                         placeholder="Password Confirmation"
//                         value={confirmPassword}
//                         onChange={(e) => setConfirmPassword(e.target.value)}
//                         required
//                       />
//                       <label htmlFor="confirm_password">Password Confirmation</label>
//                     </div>

//                     <button
//                       type="submit"
//                       className="btn custom-btn form-control mt-4 mb-3"
//                     >
//                       Create account
//                     </button>

//                     {errorMessage && (
//                       <div className="text-danger text-center mt-3">
//                         {errorMessage}
//                       </div>
//                     )}

//                     <p className="text-center">
//                       Already have an account? Please{' '}
//                       <a href="/sign-in">Sign In</a>
//                     </p>
//                   </form>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// }

// export default SignUp;




// src/pages/SignUp.js

import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';

function SignUp() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inviteCode, setInviteCode] = useState(''); // New state for invitation code
  const [errorMessage, setErrorMessage] = useState('');

  // Define the list of valid invitation codes
  const validInviteCodes = [
    'PROF1234',
    'INVITE5678',
    'ACCESS9012',
    'CODE3456',
    'SECRET7890',
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous error messages
    setErrorMessage('');

    // Check if passwords match
    if (password !== confirmPassword) {
      setErrorMessage("Passwords don't match");
      return;
    }

    // If the user is a professor, validate the invitation code
    if (role === 'professor') {
      if (!inviteCode) {
        setErrorMessage('Invitation code is required for professors.');
        return;
      }

      if (!validInviteCodes.includes(inviteCode.trim())) {
        setErrorMessage('Invalid invitation code.');
        return;
      }
    }

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Store user details in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        firstName,
        lastName,
        role,
        email,
      });

      alert('User registered and data stored!');
      
      // Clear form fields
      setFirstName('');
      setLastName('');
      setRole('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setInviteCode('');
      setErrorMessage('');
      
      // Redirect to sign-in page
      window.location.href = '/sign-in';
    } catch (error) {
      setErrorMessage(error.message.replace('firebase', 'CoursEngage'));
    }
  };

  return (
    <>
      <section className="sign-in-form section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto col-12">
              <h1 className="hero-title text-center mb-5">Sign Up</h1>
              <div className="row">
                <div className="col-lg-8 col-11 mx-auto">
                  <form id="signupForm" onSubmit={handleSubmit}>
                    <div className="form-floating my-4">
                      <input
                        type="text"
                        id="first_name"
                        className="form-control"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                      />
                      <label htmlFor="first_name">First Name</label>
                    </div>

                    <div className="form-floating my-4">
                      <input
                        type="text"
                        id="last_name"
                        className="form-control"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        required
                      />
                      <label htmlFor="last_name">Last Name</label>
                    </div>

                    <div className="form-floating my-4">
                      <select
                        className="form-select"
                        id="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        required
                      >
                        <option value="" disabled>
                          Select Role
                        </option>
                        <option value="student">Student</option>
                        <option value="professor">Professor</option>
                      </select>
                      <label htmlFor="role">You are a...</label>
                    </div>

                    {/* Conditionally render the invitation code field */}
                    {role === 'professor' && (
                      <div className="form-floating my-4">
                        <input
                          type="text"
                          id="invite_code"
                          className="form-control"
                          placeholder="Invitation Code"
                          value={inviteCode}
                          onChange={(e) => setInviteCode(e.target.value)}
                          required
                        />
                        <label htmlFor="invite_code">Invitation Code</label>
                        <p className="text-center">
                          Please enter your invitation code to proceed.
                        </p>
                      </div>
                    )}

                    <div className="form-floating my-4">
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        placeholder="Email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <label htmlFor="email">Email address</label>
                    </div>

                    <div className="form-floating my-4">
                      <input
                        type="password"
                        id="password"
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <label htmlFor="password">Password</label>
                      <p className="text-center">
                        * Must include 0-9, a-z, A-Z and be 4 to 10 characters long
                      </p>
                    </div>

                    <div className="form-floating">
                      <input
                        type="password"
                        id="confirm_password"
                        className="form-control"
                        placeholder="Password Confirmation"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                      <label htmlFor="confirm_password">Password Confirmation</label>
                    </div>

                    <button
                      type="submit"
                      className="btn custom-btn form-control mt-4 mb-3"
                    >
                      Create account
                    </button>

                    {errorMessage && (
                      <div className="text-danger text-center mt-3">
                        {errorMessage}
                      </div>
                    )}

                    <p className="text-center">
                      Already have an account? Please{' '}
                      <a href="/sign-in">Sign In</a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SignUp;
