import React from 'react';

const Preloader = () => (
  <div className="preloader">
    <div className="spinner">
      <span className="sk-inner-circle"></span>
    </div>
  </div>
);

export default Preloader;
