import React from 'react';
import { useLocation } from 'react-router-dom';

function Navbar() {
  const location = useLocation(); // Get the current route

  const activePage = (() => {
    switch (location.pathname) {
      case '/about':
        return 'about';
      case '/product-detail':
        return 'product-detail';
      case '/faq':
        return 'faq';
      case '/contact':
        return 'contact';
      default:
        return 'home';
    }
  })();

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <a className="navbar-brand" href="/">
          <strong><span>Cours</span>Engage</strong>
        </a>

        <div className="d-lg-none">
          <a href="/sign-in" className="bi-person custom-icon me-3"></a>
        </div>

        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <a className={`nav-link ${activePage === 'home' ? 'active' : ''}`} href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${activePage === 'about' ? 'active' : ''}`} href="/about">About Us</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${activePage === 'product-detail' ? 'active' : ''}`} href="/product-detail">Product Detail</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${activePage === 'contact' ? 'active' : ''}`} href="/contact">Contact</a>
            </li>
          </ul>

          <div className="d-none d-lg-block">
            <a href="/sign-in" className="bi-person custom-icon me-3"></a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
