// src/components/student/StudentSessionList.js

import React, { useState } from 'react';
import Preloader from '../Preloader';

const StudentSessionList = ({ sessions, classId, user, grades }) => {
  const [sessionSearchTerm, setSessionSearchTerm] = useState('');
  const [expandedSessions, setExpandedSessions] = useState({});
  const [loading] = useState(false);

  const handleSessionSearch = (e) => {
    setSessionSearchTerm(e.target.value);
  };

  const toggleSessionExpand = (sessionId) => {
    setExpandedSessions({
      ...expandedSessions,
      [sessionId]: !expandedSessions[sessionId],
    });
  };

  // Filter sessions based on search term
  const filteredSessions = sessions.filter((session) => {
    const sessionDate = new Date(session.start_date_time);
    return sessionDate.toLocaleDateString().includes(sessionSearchTerm);
  });

  if (loading) {
    return <Preloader />;
  }

  return (
    <>
      {/* Session Search */}
      <h3>Sessions</h3>
      <input
        type="text"
        placeholder="Search Sessions..."
        value={sessionSearchTerm}
        onChange={handleSessionSearch}
        className="form-control-sm me-2"
      />

      <div className="sessions-list">
        {filteredSessions.map((session) => (
          <div key={session.id} className="session-item">
            <div
              className="session-header d-flex justify-content-between align-items-center"
              onClick={() => toggleSessionExpand(session.id)}
            >
              <h4 className="mb-0">
                Session on {new Date(session.start_date_time).toLocaleString()}
              </h4>
              <span>{expandedSessions[session.id] ? '▲' : '▼'}</span>
            </div>

            {expandedSessions[session.id] && (
  <div className="session-details">
    <p className="mb-4">
      <strong>Total Questions:</strong> {session.total_questions}
    </p>
    
    {session.questions &&
      Object.entries(session.questions).map(([questionNumber, question]) => (
        <div
          key={questionNumber}
          className="question-item mb-4 p-3 border rounded"
        >
          <h5 className="mb-3">Question {questionNumber}</h5>

          {/* Conditional Rendering for Question Photo */}
          {question.question_photo ? (
            question.question_photo === "InClass" ? (
              <div className="alert custom-alert" role="alert">
                This question was presented in the class.
              </div>
            ) : (
              <img
                src={question.question_photo}
                alt={`Question ${questionNumber}`}
                className="img-fluid mb-3"
              />
            )
          ) : null}

          <p className="mb-2">
            <strong>Type:</strong> {question.question_type}
          </p>

          {/* Possible Answers */}
          {question.possible_answers && (
            <div className="mb-2">
              <strong>Possible Answers:</strong>
              <ul className="list-group list-group-flush">
                {question.possible_answers.map((answer, index) => (
                  <li key={index} className="list-group-item">
                    {answer}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <p className="mb-2">
            <strong>Correct Answer:</strong> {question.correct_answer || 'Not set'}
          </p>

          <p className="mb-2">
            <strong>Your Answer:</strong> {grades[session.id]?.answers?.[questionNumber]?.answer || 'Not Answered'}
          </p>

          <p className="mb-3">
            <strong>Points Earned:</strong> {grades[session.id]?.points?.[questionNumber] ?? '0'}
          </p>
        </div>
      ))}
  </div>
)}

          </div>
        ))}
      </div>
    </>
  );
};

export default StudentSessionList;
