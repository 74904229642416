// src/api/ticketApi.js
import { db, storage, auth } from '../firebase';
import {
  collection,
  doc,
  getDoc,
  setDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

/**
 * Generates a unique ticket number.
 * Format: TICKET-YYYYMMDD-HHMMSS-RANDOM
 */
const generateTicketNumber = () => {
  const now = new Date();
  const datePart = now
    .toISOString()
    .replace(/[-:T.Z]/g, '')
    .slice(0, 14); // YYYYMMDDHHMMSS
  const randomPart = Math.floor(1000 + Math.random() * 9000); // 4-digit random number
  return `TICKET-${datePart}-${randomPart}`;
};

/**
 * Checks if the user exists in Firestore under 'users/{uid}'.
 * @param {string} uid - The UID of the current user.
 * @returns {Promise<boolean>} - Returns true if user exists, else false.
 */
const checkUserExists = async (uid) => {
  try {
    const userDocRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userDocRef);
    return userSnap.exists();
  } catch (error) {
    console.error('Error checking user existence:', error);
    throw new Error('Failed to verify user.');
  }
};

/**
 * Uploads attachments to Firebase Storage under 'ticketNumber/' directory.
 * @param {string} ticketNumber - The unique ticket number.
 * @param {File[]} files - Array of File objects to upload.
 * @returns {Promise<string[]>} - Array of download URLs.
 */
const uploadAttachments = async (ticketNumber, files) => {
  try {
    const uploadPromises = files.map(async (file) => {
      const fileRef = ref(storage, `${ticketNumber}/${file.name}`);
      const snapshot = await uploadBytes(fileRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    });

    const downloadURLs = await Promise.all(uploadPromises);
    return downloadURLs;
  } catch (error) {
    console.error('Error uploading attachments:', error);
    throw new Error('Failed to upload attachments.');
  }
};

/**
 * Creates a new support ticket in Firestore.
 * @param {Object} ticketData - The ticket details.
 * @param {string} ticketData.name - Full name of the user.
 * @param {string} ticketData.email - Email address of the user.
 * @param {string} ticketData.subject - Subject of the ticket.
 * @param {string} ticketData.message - Detailed message about the issue.
 * @param {string} ticketData.priority - Priority level (Low, Medium, High).
 * @param {File[]} [ticketData.attachments] - Array of File objects (optional).
 * @returns {Promise<string>} - Returns the generated ticket number.
 */
export const createTicket = async ({
  name,
  email,
  subject,
  message,
  priority,
  attachments = [],
}) => {
  const currentUser = auth.currentUser;

  if (!currentUser) {
    throw new Error('User is not authenticated.');
  }

  const uid = currentUser.uid;

  // Step 1: Verify User Exists
  const userExists = await checkUserExists(uid);
  if (!userExists) {
    throw new Error('User does not exist in the system.');
  }

  // Step 2: Generate Unique Ticket Number
  const ticketNumber = generateTicketNumber();

  // Step 3: Upload Attachments (if any)
  let attachmentURLs = [];
  if (attachments.length > 0) {
    attachmentURLs = await uploadAttachments(ticketNumber, attachments);
  }

  // Step 4: Prepare Ticket Data
  const ticket = {
    ticketNumber,
    name,
    email,
    subject,
    message,
    priority,
    attachment: attachmentURLs, // Array of URLs
    status: 'New', // Default status
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    userId: uid, // Reference to the user
  };

  // Step 5: Store Ticket in Firestore
  try {
    const ticketsCollectionRef = collection(db, 'tickets');
    const ticketDocRef = doc(ticketsCollectionRef,ticketNumber); // Auto-generated ID
    await setDoc(ticketDocRef, ticket);

    return ticketNumber;
  } catch (error) {
    console.error('Error creating ticket:', error);
    throw new Error('Failed to create ticket.');
  }
};
